import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/dashboard.css";
import { useEffect, useState } from "react";
import {
  postSubmitForm,
  postSubmitForm_withformdata,
  postSubmitFormNoAuth,
} from "../helpers/forms_helper";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import ShowNotification from "../helpers/notification";
import moment from "moment";
import yes from "../assets/icons/check-solid.svg";
import no from "../assets/icons/times-solid.svg";
import pdf from "../assets/icons/pdf-icon.png";

function UserReport() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [students, setStudents] = useState([]);

  const [filterFields, setFilterFields] = useState({
    name: "",
    email: "",
    mobile: "",
    country: "",
  });

  const [filteredStudents, setFilteredStudents] = useState([]);

  useEffect(() => {
    loadStudents();
  }, []);

  const applyFilter = () => {
    let funnel = JSON.parse(JSON.stringify(students));

    // filter for name
    if (filterFields.name !== "") {
      funnel = funnel.filter((student) =>
        (student.first_name + " " + student.last_name)
          .toLowerCase()
          .includes(filterFields.name.toLowerCase())
      );
    }
    // filter for email
    if (filterFields.email !== "") {
      funnel = funnel.filter((student) =>
        student.email.toLowerCase().includes(filterFields.email.toLowerCase())
      );
    }

    // filter for mobile
    if (filterFields.mobile !== "") {
      funnel = funnel.filter((student) =>
        student.mobile.toLowerCase().includes(filterFields.mobile.toLowerCase())
      );
    }

    if (filterFields.country !== "") {
      funnel = funnel.filter(
        (student) => student.country === filterFields.country
      );
    }

    setFilteredStudents(funnel);
  };

  useEffect(() => {
    applyFilter();
  }, [filterFields]);

  const loadStudents = async () => {
    let url = process.env.REACT_APP_BASEURL + "students/getall";
    let response = await postSubmitForm(url, user.token, {});
    console.log(response);
    if (response.status === 1) {
      setStudents(response.data);
      setFilteredStudents(response.data);
    } else {
      ShowNotification(response.message, "Error");
    }
  };

  return (
    <>
      <section className="mt-4 mt-44">
        <div className="container-fluid">
          <div className="dashboard-container py-3" id="login-bg">
            <div className="d-flex justify-content-between align-items-center">
              <div class="container-fluid mt-0">
                <div class="row">
                  <div className="left">
                    <h3 class="admin-1">
                      <i class="fa fa-server" aria-hidden="true"></i>
                      &nbsp;User Report
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="card card-light mb-2">
              <div className="card-body">
                <div className="table-responsive admin-dashboard">
                  <table class="table table-striped sortable">
                    <thead className="table-bg">
                      <tr>
                        <th>#</th>
                        <th>
                          Name <br />
                          <input
                            type="text"
                            onChange={(e) => {
                              setFilterFields({
                                ...filterFields,
                                name: e.target.value,
                              });
                            }}
                          />
                        </th>
                        <th>
                          Email
                          <br />
                          <input
                            type="text"
                            onChange={(e) => {
                              setFilterFields({
                                ...filterFields,
                                email: e.target.value,
                              });
                            }}
                          />
                        </th>
                        <th>
                          Mobile
                          <br />
                          <input
                            type="text"
                            onChange={(e) => {
                              setFilterFields({
                                ...filterFields,
                                mobile: e.target.value,
                              });
                            }}
                          />
                        </th>
                        <th>
                          Country
                          <br />
                          <select
                            //className="custom-select country"
                            type="select"
                            name="country"
                            required
                            onChange={(e, v) => {
                              setFilterFields({
                                ...filterFields,
                                country: e.target.value,
                              });
                            }}
                          >
                            <option value="">All</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">
                              American Samoa
                            </option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua">Antigua</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijani">Azerbaijani</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Netherlands">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia-Hercegovina">
                              Bosnia-Hercegovina
                            </option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British IOT">British IOT</option>
                            <option value="Brunei Darussalam">
                              Brunei Darussalam
                            </option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">
                              Cayman Islands
                            </option>
                            <option value="Central African Rep">
                              Central African Rep
                            </option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">
                              Christmas Island
                            </option>
                            <option value="Cocos Islands">Cocos Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">
                              Czech Republic
                            </option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">
                              Dominican Republic
                            </option>
                            <option value="East Timor">East Timor</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">
                              Equatorial Guinea
                            </option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Faeroe Islands">
                              Faeroe Islands
                            </option>
                            <option value="Falkland Islands">
                              Falkland Islands
                            </option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">
                              French Polynesia
                            </option>
                            <option value="French Southern">
                              French Southern
                            </option>
                            <option value="French Southern Ter">
                              French Southern Ter
                            </option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard">Heard</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Ivory Coast">Ivory Coast</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Laos">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">
                              Marshall Islands
                            </option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia">Micronesia</option>
                            <option value="MNP">MNP</option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="NER">NER</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Neutral Zone">Neutral Zone</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">
                              Norfolk Island
                            </option>
                            <option value="North Korea">North Korea</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">
                              Papua New Guinea
                            </option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Helena">Saint Helena</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Pierre">Saint Pierre</option>
                            <option value="Saint Vincent">Saint Vincent</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Scotland">Scotland</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovak Republic">
                              Slovak Republic
                            </option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">
                              Solomon Islands
                            </option>
                            <option value="Somali Democratic">
                              Somali Democratic
                            </option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia">South Georgia</option>
                            <option value="South Korea">South Korea</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="St. Kitts and Nevis">
                              St. Kitts and Nevis
                            </option>
                            <option value="STP">STP</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard">Svalbard</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="TCA">TCA</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Toga">Toga</option>
                            <option value="Togolese">Togolese</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tongo">Tongo</option>
                            <option value="Trinidad and Tobago">
                              Trinidad and Tobago
                            </option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab">United Arab</option>
                            <option value="United Kingdom">
                              United Kingdom
                            </option>
                            <option value="United States">United States</option>
                            <option value="Upper Volta">Upper Volta</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="USSR(Former)">USSR(Former)</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City">Vatican City</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="VI, British">VI, British</option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands, USA">
                              Virgin Islands, USA
                            </option>
                            <option value="Western Sahara">
                              Western Sahara
                            </option>
                            <option value="WLF">WLF</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Yugoslavia">Yugoslavia</option>
                            <option value="Zaire">Zaire</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                        </th>
                        <th>Highest Degree</th>
                        <th>Designation</th>
                        <th>Institute</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredStudents &&
                        filteredStudents.map((item, index) => {
                          return (
                            <tr>
                              <td style={{ textAlign: "left" }}>{index + 1}</td>
                              <td style={{ textAlign: "left" }}>
                                {item.first_name} {item.last_name}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {item.email}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {item.mobile}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {item.country}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {item.highest_degree}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {item.designation}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {item.institute}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between custom-inner-container">
              <p className="text count">Total Count: {students.length}</p>
              <div className="custom-pagination d-flex align-items-center">
                <i className="fa fa-angle-left" aria-hidden="true">
                  {"<"}
                </i>
                <p className="text-small pagination">1-15</p>
                <i className="fa fa-angle-right" aria-hidden="true">
                  {">"}
                </i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserReport;
