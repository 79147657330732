import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/landing.css";

function Navbar() {
  return (
    <>

      <footer>
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-5">
                <h4>About TexScript</h4>
                <p>
                  We the texscript.com provide typesetting services (specifically Latex typesetting services) of scientific documents like Research Papers, Articles, Thesis, Dissertation etc.
                </p>
                <p>
                  Since these days every standard and reputed publisher (Journal, book publisher etc.) prefers to publish the documents that are typeset in latex. So, we are here to typeset your scientific document in Latex within a time and cost-efficient manner. {" "}
                </p>
              </div>

              {/* <div class="col-md-3">
                <h4>Information</h4>
                <ul class="address1">
                  <li><i class="fa fa-map-marker"></i>C-25, Aliganj, Lucknow - 226024, Uttar Pradesh, India. </li>
                  <li><i class="fa fa-envelope"></i><a href="mailto:#">info@texscript.com</a></li>
                  <li><i class="fa fa-mobile" aria-hidden="true"></i> <a href="tel:12 34 56 78 90">+91-9889665224</a></li>
                </ul>
              </div> */}

              <div class="col-md-3">
                <h4>Quick Links</h4>
                <ul>





                  <p>
                    <a class="text-white" href="https://api-texscript.getdemos.in/uploads/general_sample.pdf" target="_blank">
                      {" "}
                      <i
                        class="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      &nbsp;Sample Document
                    </a>
                  </p>

                  <p>
                    <a class="text-white" href="/privacy-policy">
                      {" "}
                      <i
                        class="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      &nbsp;Privacy Policy
                    </a>
                  </p>

                  <p>
                    <a class="text-white" href=" /refunds-and-cancellation-policy">
                      {" "}
                      <i
                        class="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      &nbsp;Refunds and Cancellation Policy
                    </a>
                  </p>



                  <p>
                    <a class="text-white" href="/terms-conditions">
                      {" "}
                      <i
                        class="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      &nbsp;Terms &amp; Conditions
                    </a>
                  </p>
                  <p>
                    <a class="text-white" href="/">
                      <i
                        class="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      &nbsp;Contact Us
                    </a>
                  </p>
                </ul>
              </div>
              <div class="col-md-3">
                <h4>Follow us</h4>
                <ul class="social-icon htp">
                  <li>
                    <a
                      href="https://facebook.com/Tex-Script-106435758848326/#!/home.php?paipv=1"
                      target="_blank"
                    >
                      <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/texscript/"
                      target="_blank"
                    >
                      <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/tex-script-653a30248"
                      target="_blank"
                    >
                      <i class="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <p className="tp"> All Right Reserved - ©2022 Texscript.Com</p>
              </div>
              <div class="col-md-6">
                <p className="tp-1">
                  Powered by :
                  <a
                    className="text-white"
                    href="https://onebigbit.com"
                    target="_blank"
                  >
                    {" "}
                    OneBigBit Technologies Pvt. Ltd.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Navbar;
