import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/dashboard.css";
import { useEffect, useState } from "react";
import {
  postSubmitForm,
  postSubmitForm_withformdata,
  postSubmitFormNoAuth,
} from "../helpers/forms_helper";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import ShowNotification from "../helpers/notification";
import moment from "moment";
import del from "../assets/icons/trash-can-regular.svg";
import edit from "../assets/icons/pen-to-square-regular.svg";

function Settings() {
  const user = JSON.parse(localStorage.getItem("user"));

  const [settings, setSettings] = useState(null);
  const [services, setServices] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [updatePriceModal, setUpdatePriceModal] = useState(false);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    let url = process.env.REACT_APP_BASEURL + "settings/get";
    let response = await postSubmitForm(url, user.token, {});

    if (response.status === 1) {
      setSettings(response.data);
      setConfigureDelay(response.data.delay_setting);

      let service_list = [
        ...new Set(response.data.prices.map((item) => item.service_type)),
      ];
      let services = [];

      service_list.map((item) => {
        let obj = { service_type: item, regular_price: 0, urgent_price: 0 };

        response.data.prices.map((it) => {
          if (it.service_type === item && it.document_type === "Regular") {
            obj.regular_price = it.price;
          } else if (
            it.service_type === item &&
            it.document_type === "Urgent"
          ) {
            obj.urgent_price = it.price;
          }
        });
        services.push(obj);
      });

      setServices(services);
    } else {
      ShowNotification(response.message, "Error");
    }
  };

  const [referralDiscount, setReferralDiscount] = useState(null);
  const [generalDiscount, setGeneralDiscount] = useState(null);
  const [generalDocumentFile, setGeneralDocumentFile] = useState(null);

  const [newRegularPrice, setNewRegularPrice] = useState(null);
  const [newUrgentPrice, setNewUrgentPrice] = useState(null);

  const [configureDelay, setConfigureDelay] = useState({
    is_active: null,
    date_time: null,
  });

  const handleGeneralDocumentFile = async (e, v) => {
    e.preventDefault();

    if (generalDocumentFile === null) {
      return ShowNotification("Please select a file to upload.", "error");
    }

    let formData = new FormData();

    formData.append("document_file", generalDocumentFile);

    let url = process.env.REACT_APP_BASEURL + "settings/sample";
    let response = await postSubmitForm_withformdata(url, user.token, formData);
    if (response.status === 1) {
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handleConfigureDelay = async (e, v) => {
    e.preventDefault();

    //console.log(settings.delay_setting);

    let url = process.env.REACT_APP_BASEURL + "settings/update";
    let response = await postSubmitForm(url, user.token, configureDelay);
    if (response.status === 1) {
      loadSettings();

      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handleUpdateReferralPercentage = async (e, v) => {
    e.preventDefault();

    console.log(referralDiscount);

    let url = process.env.REACT_APP_BASEURL + "settings/updaterefpercent";
    let response = await postSubmitForm(url, user.token, {
      ref_percent: referralDiscount,
    });
    if (response.status === 1) {
      loadSettings();
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handleUpdateGeneralDiscount = async (e, v) => {
    e.preventDefault();

    console.log(generalDiscount);

    let url =
      process.env.REACT_APP_BASEURL + "settings/update_general_discount";
    let response = await postSubmitForm(url, user.token, {
      general_discount: generalDiscount,
    });
    if (response.status === 1) {
      loadSettings();
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handleUpdatePrice = async (e, v) => {
    e.preventDefault();

    let url = process.env.REACT_APP_BASEURL + "settings/updateprice";
    let response = await postSubmitForm(url, user.token, {
      service_type: selectedService.service_type,
      regular_price: newRegularPrice,
      urgent_price: newUrgentPrice,
    });
    if (response.status === 1) {
      loadSettings();
      setUpdatePriceModal(false);
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  return (
    <>
      <section className="mt-4 mt-44">
        <div className="container-fluid">
          <div className="dashboard-container py-3" id="login-bg">
            <div class="dashboard-container py-3" id="login-bg">
              <div class="container-fluid">
                <h3 class="admin-1">
                  {" "}
                  <i class="fa fa-cogs" aria-hidden="true"></i>
                  &nbsp;Settings
                </h3>
              </div>
            </div>

            <div class="container-fluid">
              <Row>
                <Col md={6} className="static">
                  <div
                    id="delayed"
                    class="justify-content-between align-items-end"
                  >
                    <Row style={{ height: "204px" }}>
                      <Col md={12}>
                        <h5 className="D-heading">
                          <i
                            class="fa fa-calendar-check-o"
                            aria-hidden="true"
                          ></i>
                          &nbsp; Configure Delay
                        </h5>
                      </Col>
                      <Col md={6}>
                        <label>Enable/Disable</label>
                        <br />
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={configureDelay && configureDelay.is_active}
                            onChange={(e) => {
                              console.log(e.target.checked);
                              setConfigureDelay({
                                ...configureDelay,
                                is_active: e.target.checked,
                              });
                            }}
                          />
                          <span class="slider"></span>
                        </label>
                      </Col>
                      <Col md={6}>
                        <label for="date">Date until deactive</label>

                        <input
                          type="datetime-local"
                          class="form-control"
                          id="date"
                          value={moment(configureDelay.date_time).format(
                            "YYYY-MM-DDTHH:mm:ss"
                          )}
                          onChange={(e) => {
                            setConfigureDelay({
                              ...configureDelay,
                              date_time: moment(e.target.value).format(
                                "YYYY-MM-DDTHH:mm:ss"
                              ),
                            });
                          }}
                        />
                      </Col>
                      <Col md={12} className="d-flex justify-content-end mt-5">
                        <button
                          class="custom-button cta-submit"
                          onClick={(e, v) => {
                            handleConfigureDelay(e, v);
                          }}
                        >
                          Confirm
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6} className="static">
                  <div
                    id="delayed"
                    class="justify-content-between align-items-end"
                  >
                    <Col md={12}>
                      <h5 className="D-heading">
                        <i
                          class="fa fa-calendar-check-o"
                          aria-hidden="true"
                        ></i>
                        &nbsp; General Sample
                      </h5>
                    </Col>
                    <Col md={12}>
                      <br />
                      <input
                        type="file"
                        class="file-control"
                        name="document_file"
                        required
                        onChange={(e, v) => {
                          setGeneralDocumentFile(e.target.files[0]);
                        }}
                      />
                      <p>&nbsp;</p>
                    </Col>

                    <div className="container">
                      <Row>
                        <Col md={4}>
                          <button
                            class="custom-button pull-right cta-submit bbb"
                            onClick={(e, v) => {
                              handleGeneralDocumentFile(e, v);
                            }}
                          >
                            Confirm
                          </button>
                        </Col>

                        <Col md={8}>
                          <a
                            href="https://api-texscript.getdemos.in/uploads/general_sample.pdf"
                            target="_blank"
                            class="custom-button pull-right cta-submit"
                          >
                            View File
                          </a>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col md={6}>
                  <div
                    id="delayed"
                    class="justify-content-between align-items-end"
                  >
                    <Row style={{ height: "204px" }}>
                      <Col md={12}>
                        <h5 className="D-heading">
                          <i
                            class="fa fa-calendar-check-o"
                            aria-hidden="true"
                          ></i>
                          &nbsp; Referral Percentage
                        </h5>
                      </Col>

                      <Col md={6}>
                        <label>Discount Percentage</label>
                        <br />
                        <input
                          type="text"
                          onChange={(e) => {
                            setReferralDiscount(e.target.value);
                          }}
                          className="form-control"
                        />
                      </Col>
                      <Col md={6}>
                        <br />
                        <button
                          class="custom-button cta-submit mt-1"
                          onClick={(e, v) => {
                            handleUpdateReferralPercentage(e, v);
                          }}
                        >
                          Confirm
                        </button>
                      </Col>

                      <Col md={12}>
                        <div class="textBackdrop">
                          <p>
                            Discount on referral code is set to{" "}
                            <span class="pull-right disc-amt">
                              <b> {settings && settings.ref_percent}%</b>
                            </span>
                            <br />
                            &nbsp;
                          </p>
                        </div>
                      </Col>

                      <Col
                        md={12}
                        className="d-flex justify-content-end mt-5"
                      ></Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6}>
                  <div
                    id="delayed"
                    class="justify-content-between align-items-end"
                  >
                    <Row style={{ height: "204px" }}>
                      <Col md={12}>
                        <h5 className="D-heading">
                          <i
                            class="fa fa-calendar-check-o"
                            aria-hidden="true"
                          ></i>
                          &nbsp; General Discount
                        </h5>
                      </Col>
                      <Col md={6}>
                        <label>Discount Percentage</label>
                        <br />
                        <input
                          type="text"
                          onChange={(e) => {
                            setGeneralDiscount(e.target.value);
                          }}
                          className="form-control"
                        />
                      </Col>
                      <Col md={6}>
                        <br />
                        <button
                          class="custom-button cta-submit mt-1"
                          onClick={(e, v) => {
                            handleUpdateGeneralDiscount(e, v);
                          }}
                        >
                          Confirm
                        </button>
                      </Col>
                      <Col md={12}>
                        <div class="textBackdrop">
                          <p>
                            General Discount is set to{" "}
                            <span class="pull-right disc-amt">
                              <b> {settings && settings.general_discount}%</b>
                            </span>
                            <br />
                            &nbsp;
                          </p>
                        </div>
                      </Col>

                      <Col
                        md={12}
                        className="d-flex justify-content-end mt-5"
                      ></Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12}>
                  <div
                    id="delayed "
                    class="justify-content-between align-items-end d-1"
                  >
                    <Row>
                      <Col md={12}>
                        <h5 className="D-heading">
                          <i
                            class="fa fa-calendar-check-o"
                            aria-hidden="true"
                          ></i>
                          &nbsp; Services
                        </h5>
                      </Col>
                      <Col md={12}>
                        <div className="card card-light mb-2">
                          <div className="card-body">
                            <div className="table-responsive  admin-dashboard">
                              <table class="table table-striped responsive">
                                <thead className="table-bg">
                                  <tr>
                                    <th>#</th>
                                    <th>Service Type</th>
                                    <th>Regular Price</th>
                                    <th>Urgent Price</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {services &&
                                    services.map((item, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td style={{ textAlign: "left" }}>
                                            {item.service_type}
                                          </td>
                                          <td>
                                            {(item.regular_price / 100).toFixed(
                                              2
                                            )}
                                          </td>
                                          <td>
                                            {(item.urgent_price / 100).toFixed(
                                              2
                                            )}
                                          </td>
                                          <td>
                                            <img
                                              onClick={() => {
                                                setSelectedService(item);
                                                setNewRegularPrice(
                                                  (
                                                    item.regular_price / 100
                                                  ).toFixed(2)
                                                );
                                                setNewUrgentPrice(
                                                  (
                                                    item.urgent_price / 100
                                                  ).toFixed(2)
                                                );
                                                setUpdatePriceModal(true);
                                              }}
                                              src={edit}
                                              style={{
                                                width: "15px",
                                                cursor: "pointer",
                                              }}
                                              title="Edit Price"
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>

      {/* Update Price Modal Start */}
      <Modal
        size="md"
        isOpen={updatePriceModal}
        toggle={() => setUpdatePriceModal(!updatePriceModal)}
      >
        <ModalHeader>
          Update Price
          <span
            className="cross"
            title="Close"
            onClick={() => {
              setUpdatePriceModal(false);
            }}
          >
            x
          </span>
        </ModalHeader>
        <ModalBody>
          <div id="uploadDraftFile">
            <div class="d-flex file-group">
              <Row>
                <Col md={12}>
                  <label>Service Type</label>
                  <input
                    type="text"
                    class="form-control"
                    name="service_type"
                    value={selectedService && selectedService.service_type}
                    disabled="true"
                    required
                  />
                  <br />
                </Col>

                <Col md={6}>
                  <label>Regular Price</label>

                  <input
                    type="number"
                    class="form-control"
                    name="regular_price"
                    value={newRegularPrice && newRegularPrice}
                    onChange={(e) => {
                      setNewRegularPrice(e.target.value);
                    }}
                    required
                  />
                </Col>
                <Col md={6}>
                  <label>Urgent Price</label>
                  <input
                    type="number"
                    class="form-control"
                    name="urgent_price"
                    value={newUrgentPrice && newUrgentPrice}
                    onChange={(e) => {
                      setNewUrgentPrice(e.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-12">
                <button
                  class="custom-button pull-right cta-submit"
                  onClick={(e, v) => {
                    handleUpdatePrice(e, v);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/*  Update Price Modal End */}
    </>
  );
}

export default Settings;
