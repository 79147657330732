import React, { useEffect, useState } from "react";
export const UserContext = React.createContext();

const LoginContext = ({ subPages }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    const local_user = localStorage.getItem("user");
    if (local_user) {
      setUser(JSON.parse(local_user));
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {subPages}
    </UserContext.Provider>
  );
};

export default LoginContext;
