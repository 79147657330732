import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/landing.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";

//import "./assets/js/jquery-3.6.0.min.js";
//import "./assets/js/bootstrap.min.js";
//import "./assets/js/init.js";

import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import StudentDashboard from "./components/StudentDashboard";
import StudentProfile from "./components/StudentProfile";
import StudentChangePassword from "./components/StudentChangePassword";
import AdminChangePassword from "./components/AdminChangePassword";
import AdminDashboard from "./components/AdminDashboard";
import Settings from "./components/Settings";
import Coupons from "./components/Coupons";
import UserReport from "./components/UserReport";
import DeclinedPapers from "./components/DeclinedPapers";
import FinalPapers from "./components/FinalPapers";

import StudentReferrals from "./components/StudentReferrals";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundsAndCancellationPolicy from './components/RefundsAndCancellationPolicy';
import Resetpassword from "./components/resetpassword";

function App() {
  return (
    <>
      <Router>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/student-dashboard" element={<StudentDashboard />} />
          <Route path="/student-profile" element={<StudentProfile />} />
          <Route
            path="/student-change-password"
            element={<StudentChangePassword />}
          />
          <Route
            path="/admin-change-password"
            element={<AdminChangePassword />}
          />
          <Route path="/settings" element={<Settings />} />
          <Route path="/user-report" element={<UserReport />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/declined-papers" element={<DeclinedPapers />} />
          <Route path="/final-papers" element={<FinalPapers />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/register" element={<Register />} />

          <Route path="/student-referrals" element={<StudentReferrals />} />
          <Route path="/reset-password" element={<Resetpassword />} />

          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refunds-and-cancellation-policy" element={<RefundsAndCancellationPolicy />} />
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;
