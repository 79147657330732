import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/dashboard.css";

import Papers from "./Papers";

function FinalPapers() {
  return <Papers title="Final Papers" status={["Final"]} />;
}

export default FinalPapers;
