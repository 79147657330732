import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/dashboard.css";

import Papers from "./Papers";

function AdminDashboard() {
  return (
    <Papers
      title="Admin Dashboard"
      status={["Pending", "First Draft", "Accepted", "Final Document"]}
    />
  );
}

export default AdminDashboard;
