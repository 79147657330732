import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/register.css";
import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { postSubmitFormNoAuth, postSubmitForm } from "../helpers/forms_helper";
import ShowNotification from "../helpers/notification";
import { UserContext } from "./LoginContext";
import moment from "moment";

function StudentReferrals() {
  const { setUser } = useContext(UserContext);
  const user = JSON.parse(localStorage.getItem("user"));

  const [update, setUpdate] = useState({});

  useEffect(() => {
    setUpdate(user);
  }, []);

  const handleUpdate = async (e, v) => {
    e.preventDefault();

    let url = process.env.REACT_APP_BASEURL + "students/update";
    let response = await postSubmitForm(url, user.token, update);

    if (response.status === 1) {
      localStorage.setItem("user", JSON.stringify(update));
      setUser(update);
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  return (
    <>
      <section class="mt-4 mt-44">
        <div className="container-fluid">

          <div class="dashboard-container py-3" id="login-bg">
            <div class="container-fluid mt-0">
              <h3 class="admin-1"><i class="fa fa-shield" aria-hidden="true"></i>

                &nbsp;My Referrals</h3>
            </div>
          </div>


          <div className="container">
            <div className="card">
              <div className="col-md-12">
                <div className="row">
                  <div className="card-body">
                    <div id="paymentInfo">
                      <div class="col text-center">
                        <h5 class="textBackdrop">
                          Referral Bonus Amount :{" "}
                          <span class="pull-right-1">
                            ${user && (user.referral_bonus / 100).toFixed(2)}
                          </span>
                        </h5>
                      </div>
                    </div>
                    <table class="table table-striped sortable">
                      <thead className="table-bg">
                        <tr>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {user &&
                          user.referral_transactions.map((item) => {
                            return (
                              <tr>
                                <td>
                                  {moment(item.transaction_datetime).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </td>
                                {item.transaction_type == "Credit" ? (
                                  <td
                                    style={{ color: "green", fontWeight: "bold" }}
                                  >
                                    ${(item.transaction_amount / 100).toFixed(2)}
                                  </td>
                                ) : (
                                  <td
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    ${(item.transaction_amount / 100).toFixed(2)}{" "}
                                  </td>
                                )}

                                <td>{item.transaction_remarks}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>



                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
}

export default StudentReferrals;
