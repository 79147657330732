import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/dashboard.css";
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  postSubmitForm,
  postSubmitForm_withformdata,
  postSubmitFormNoAuth,
} from "../helpers/forms_helper";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import ShowNotification from "../helpers/notification";
import moment from "moment";
import yes from "../assets/icons/check-solid.svg";
import no from "../assets/icons/times-solid.svg";
import pdf from "../assets/icons/pdf-icon.png";
import zip from "../assets/icons/zip-icon.png";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

function StudentDashboard() {
  const user = JSON.parse(localStorage.getItem("user"));

  const currency = "USD";
  const style = { layout: "horizontal", height: 40 };

  const [papers, setPapers] = useState([]);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [current_date, setCurrent_Date] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );
  const [selectedPaper, setSelectedPaper] = useState(null);

  const loadPapers = async () => {
    let url = process.env.REACT_APP_BASEURL + "papers/getbyid";
    let response = await postSubmitForm(url, user.token, {});
    if (response.status === 1) {
      setPapers(response.data);
    } else {
      ShowNotification(response.message, "Error");
    }
  };

  const loadSettings = async () => {
    let url = process.env.REACT_APP_BASEURL + "settings/get";
    let response = await postSubmitFormNoAuth(url, {});

    if (response.status === 1) {
      setSettings(response.data);
    } else {
      ShowNotification(response.message, "Error");
    }
  };

  useEffect(() => {
    loadPapers();
    loadSettings();
  }, []);

  const [choosePriorityModal, setChoosePriorityModal] = useState(false);
  const [uploadDocumentModal, setUploadDocumentModal] = useState(false);
  const [referralCodeModal, setReferralCodeModal] = useState(false);
  const [sampleDocumentModal, setSampleDocumentModal] = useState(false);
  const [paymentInfoModal, setPaymentInfoModal] = useState(false);
  const [finalDocumentFileModal, setFinalDocumentFileModal] = useState(false);
  const [firstDraftReplyModal, setFirstDraftReplyModal] = useState(false);
  const [firstDraftReplyFile, setSetFirstDraftReplyFile] = useState(null);
  const [paypalModal, setPaypalModal] = useState(false);

  const [paymentInfo, setPaymentInfo] = useState();
  const [amount, setAmount] = useState();
  const [additionalInformation, setAdditionalInformation] = useState();
  const [isFinal, setIsFinal] = useState();
  const [coupon, setCoupon] = useState();

  const [uploadDocument, setUploadDocument] = useState({
    name_of_document: "",
    tentative_pages: "",
    document_type: "",
    service_type: "",
    additional_information: "",
    document_file: null,
  });

  const handleUploadDocument = async (e, v) => {
    e.preventDefault();
    console.log(uploadDocument);
    setLoading(true);
    if (uploadDocument.name_of_document === "") {
      setLoading(false);
      return ShowNotification("Please enter Name of document.", "error");
    } else if (uploadDocument.tentative_pages === "") {
      setLoading(false);
      return ShowNotification("Please enter Tentative pages.", "error");
    } else if (uploadDocument.document_type === "") {
      setLoading(false);
      return ShowNotification("Please enter Document type cannot.", "error");
    } else if (uploadDocument.service_type === "") {
      setLoading(false);
      return ShowNotification("Please choose Service type.", "error");
    } else if (uploadDocument.document_file === null) {
      setLoading(false);
      return ShowNotification("Please select a file to upload.", "error");
    }

    let formData = new FormData();

    formData.set("name_of_document", uploadDocument.name_of_document);
    formData.set("tentative_pages", uploadDocument.tentative_pages);
    formData.set("document_type", uploadDocument.document_type);
    formData.set("service_type", uploadDocument.service_type);
    formData.set(
      "additional_information",
      uploadDocument.additional_information
    );
    formData.append("document_file", uploadDocument.document_file);
    let url = process.env.REACT_APP_BASEURL + "papers/insert";
    let response = await postSubmitForm_withformdata(url, user.token, formData);
    if (response.status === 1) {
      setLoading(false);
      loadPapers();
      setUploadDocumentModal(!uploadDocumentModal);
      ShowNotification(
        "File Successfully Uploaded. Please wait while we review your information.",
        "success"
      );
    } else {
      ShowNotification(response.message, "error");
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handlePayment = async (e, v) => {
    if (amount * 100 > paymentInfo.amount_balance || amount <= 0) {
      ShowNotification("Invalid amount to pay.", "error");
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const values_to_send = { paper_id: paymentInfo._id, amount: amount };
    let url = process.env.REACT_APP_BASEURL + "payments/initiate";
    const response = await postSubmitForm(url, user.token, values_to_send);

    if (!response) {
      alert("Server error. Are you online?");
      return;
    } else if (response.status === 0) {
      alert(response.message);
      return;
    }

    const { amount, id: order_id, currency } = response;
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Texscript",
      description: "texscript.com",
      image: "https://cdn.razorpay.com/logos/G5MiDsyYrhOalS_medium.png",
      order_id: order_id,
      handler: async function (response) {
        //console.log(response);
        const data = {
          orderCreationId: order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        };
        //console.log(data);
        const result = await postSubmitFormNoAuth(
          process.env.REACT_APP_BASEURL + "payments/callback",
          data
        );
        //console.log(result);
        if (result.status === 1) {
          setPaymentInfoModal(!paymentInfoModal);
          loadPapers();
          window.scrollTo(0, 0);
          ShowNotification("Payment Successful.", "success");
        }
      },
      prefill: {
        name: paymentInfo.student_details.name,
        email: paymentInfo.student_details.email,
        contact: paymentInfo.student_details.mobile,
      },
      notes: {
        student_id: paymentInfo.student_details._id,
        name: paymentInfo.student_details.name,
        paper_id: paymentInfo._id,
      },
      theme: {
        color: "#F37254",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleTentativePageStatus = async (id, tentative_page_status) => {
    let url = process.env.REACT_APP_BASEURL + "papers/tentative_page_status";

    let response = await postSubmitForm(url, user.token, {
      id,
      tentative_page_status,
    });

    if (response.status === 1) {
      if (tentative_page_status === true) {
        ShowNotification(
          "Thanks for Acceptance. Please Pay Token Amount to Proceed.",
          "success"
        );
      } else {
        ShowNotification(response.message, "success");
      }

      loadPapers();
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handleFirstDraftReply = async (e, v) => {
    e.preventDefault();

    console.log(firstDraftReplyFile);

    let formData = new FormData();

    formData.set("id", selectedPaper._id);
    formData.set("is_final", isFinal);
    formData.set("additional_information", additionalInformation);
    formData.append("document_file", firstDraftReplyFile);

    let url = process.env.REACT_APP_BASEURL + "papers/firstdraftreply";
    let response = await postSubmitForm_withformdata(url, user.token, formData);

    if (response.status === 1) {
      console.log(response);
      loadPapers();
      setFirstDraftReplyModal(!firstDraftReplyModal);
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handleApplyCoupon = async (e, v) => {
    e.preventDefault();

    console.log(coupon);

    let url = process.env.REACT_APP_BASEURL + "papers/applycoupon";
    let response = await postSubmitForm(url, user.token, {
      paper_id: paymentInfo._id,
      coupon_code: coupon,
    });

    if (response.status === 1) {
      loadPapers();
      setPaymentInfo(response.data);
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handlePayFromReferralBonus = async (e, v) => {
    e.preventDefault();

    let url = process.env.REACT_APP_BASEURL + "papers/pay_from_referral_bonus";
    let response = await postSubmitForm(url, user.token, {
      paper_id: paymentInfo._id,
    });

    if (response.status === 1) {
      loadPapers();
      setPaymentInfo(response.data);
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  return (
    <>
      <section class="mt-4 mt-44">
        <div className="container-fluid mt-9">
          <div className="row">
            <div className="col-md-3">
              <div class="container-fluid">
                <h3 class="admin-1">
                  {" "}
                  <i class="fa fa-tachometer" aria-hidden="true"></i>
                  &nbsp;Dashboard
                </h3>
              </div>
            </div>

            <div className="col-md-3 sample-doc">
              <text
                className="text"
                id="referral_code"
                onClick={() => {
                  setReferralCodeModal(!referralCodeModal);
                }}
              >
                Show Referral Code
              </text>
            </div>

            <div className="col-md-3 sample-doc">
              <text
                className="text"
                id="sample_doc"
                onClick={() => {
                  setSampleDocumentModal(!sampleDocumentModal);
                }}
              >
                Sample Document
              </text>
            </div>

            <div className="col-md-3 mt-15 sample-doc">
              <button
                className="button bg left"
                onClick={() => {
                  setChoosePriorityModal(!choosePriorityModal);
                }}
              >
                <i class="fa fa-upload" aria-hidden="true"></i> Upload Document
              </button>
            </div>
          </div>
        </div>

        <div className="container-fluid mt00">
          <div className="card card-light mb-12">
            <div className="card-body">
              <div className="table-responsive">
                <table class="table table-striped ">
                  <thead className="table-bg">
                    <tr>
                      <th>Document ID</th>
                      <th>Name of Document</th>
                      <th>Upload Date</th>
                      <th>Progress</th>
                      <th>Tentative Pages</th>
                      <th>Final Pages</th>
                      <th>Status</th>
                      <th>Payment Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    {papers &&
                      papers.map((item) => {
                        return (
                          <tr>
                            {/* original document */}
                            <th
                              className={
                                item.action_required_by === "Student"
                                  ? "notify"
                                  : ""
                              }
                            >
                              <a
                                href={item.original_document_url}
                                target="_blank"
                              >
                                {item.document_id}
                              </a>
                            </th>
                            {/* name of document */}
                            <td>{item.name_of_document}</td>
                            {/* upload date */}
                            <td>
                              {moment(item.createdAt).format("DD/MM/YYYY")}
                            </td>
                            {/* progress */}
                            <td>{item.progress} %</td>
                            {/* tentative pages */}
                            <td>
                              {item.tentative_pages}
                              <br />
                              {item.tentative_page_status === false &&
                              item.status === "Accepted" ? (
                                <>
                                  <span
                                    style={{
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleTentativePageStatus(item._id, true);
                                    }}
                                  >
                                    Accept
                                  </span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => {
                                      handleTentativePageStatus(
                                        item._id,
                                        false
                                      );
                                    }}
                                  >
                                    Reject
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </td>

                            {/* final pages */}
                            <td>{item.final_pages}</td>
                            {/* status */}
                            <td>
                              <b>
                                {item.status === "First Draft" &&
                                item.first_draft_reply_uploaded === false ? (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setSelectedPaper(item);
                                      setFirstDraftReplyModal(
                                        !firstDraftReplyModal
                                      );
                                    }}
                                  >
                                    {item.status}
                                  </a>
                                ) : item.status === "Final" ||
                                  item.status === "Final Document" ? (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setSelectedPaper(item);
                                      setFinalDocumentFileModal(true);
                                    }}
                                  >
                                    {item.status}
                                  </a>
                                ) : (
                                  item.status
                                )}
                              </b>
                            </td>
                            {/* payment info */}
                            <td>
                              {item.status === "Declined" ||
                              item.status === "Pending" ? (
                                ""
                              ) : item.amount_balance <= 0 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPaymentInfo(item);
                                    setAmount(
                                      (item.amount_balance / 100).toFixed(2)
                                    );
                                    setPaymentInfoModal(!paymentInfoModal);
                                  }}
                                >
                                  Paid
                                </span>
                              ) : item.amount_balance > 0 &&
                                item.status === "Accepted" ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPaymentInfo(item);
                                    setAmount(
                                      (item.amount_balance / 100).toFixed(2)
                                    );
                                    setPaymentInfoModal(!paymentInfoModal);
                                  }}
                                >
                                  Pay token amount
                                </span>
                              ) : item.amount_balance > 0 &&
                                item.status === "Final Document" ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPaymentInfo(item);
                                    setAmount(
                                      (item.amount_balance / 100).toFixed(2)
                                    );
                                    setPaymentInfoModal(!paymentInfoModal);
                                  }}
                                >
                                  Pay balance amount <br />
                                  to get final file.
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPaymentInfo(item);
                                    setAmount(
                                      (item.amount_balance / 100).toFixed(2)
                                    );
                                    setPaymentInfoModal(!paymentInfoModal);
                                  }}
                                >
                                  Pay remaining amount.
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between custom-inner-container">
            <p className="text count">Total Count: {papers.length}</p>
            <div className="custom-pagination d-flex align-items-center">
              <i className="fa fa-angle-left" aria-hidden="true">
                {"<"}
              </i>
              <p className="text-small pagination">1-15</p>
              <i className="fa fa-angle-right" aria-hidden="true">
                {">"}
              </i>
            </div>
          </div>
        </div>

        {/* Choose Priority Modal Start */}
        <Modal
          size="md"
          isOpen={choosePriorityModal}
          toggle={() => setChoosePriorityModal(!choosePriorityModal)}
          backdrop="static"
        >
          <ModalHeader>
            Choose Priority
            <span
              className="cross"
              title="Close"
              onClick={() => {
                setChoosePriorityModal(false);
              }}
            >
              x
            </span>
          </ModalHeader>
          <ModalBody>
            <div id="uploadDoc">
              {settings && settings.delay_setting.is_active ? (
                <div className="d-flex priroty-wrap">
                  <div
                    className="type delayed text-center mr-3"
                    data-priority="DELAYED"
                    onClick={() => {
                      setUploadDocument({
                        ...uploadDocument,
                        document_type: "Regular",
                      });
                      setUploadDocumentModal(!uploadDocumentModal);
                      setChoosePriorityModal(!choosePriorityModal);
                    }}
                  >
                    <div className="delayed-inner">
                      <h3>REGULAR</h3>
                      <p>Delivery in 5 - 7 business days.</p>
                    </div>
                    <div className="select">SELECT</div>
                  </div>
                  <div
                    className="type urgent text-center"
                    data-priority="URGENT"
                    onClick={() => {
                      setUploadDocument({
                        ...uploadDocument,
                        document_type: "Urgent",
                      });
                      setUploadDocumentModal(!uploadDocumentModal);
                      setChoosePriorityModal(!choosePriorityModal);
                    }}
                  >
                    <div className="urgent-inner">
                      <h3>URGENT</h3>
                      <p>Delivery in 2 - 3 business days.</p>
                    </div>
                    <div className="select">SELECT</div>
                  </div>
                </div>
              ) : (
                <div className="d-flex priroty-wrap">
                  <div
                    className="type regular text-center mr-3"
                    data-priority="DELAYED"
                    onClick={() => {
                      setUploadDocument({
                        ...uploadDocument,
                        document_type: "Regular",
                      });
                      setUploadDocumentModal(!uploadDocumentModal);
                      setChoosePriorityModal(!choosePriorityModal);
                    }}
                  >
                    <div className="regular-inner">
                      <h3>REGULAR</h3>
                      <p>Delivery in 2 - 4 business days.</p>
                    </div>
                    <div className="select">SELECT</div>
                  </div>
                  <div
                    className="type urgent text-center"
                    data-priority="URGENT"
                    onClick={() => {
                      setUploadDocument({
                        ...uploadDocument,
                        document_type: "Urgent",
                      });
                      setUploadDocumentModal(!uploadDocumentModal);
                      setChoosePriorityModal(!choosePriorityModal);
                    }}
                  >
                    <div className="urgent-inner">
                      <h3>URGENT</h3>
                      <p>Delivery in 1 - 2 business days.</p>
                    </div>
                    <div className="select">SELECT</div>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
        {/* Choose Priority Modal End */}

        {/* Upload Document Modal Start */}
        <Modal
          size="lg"
          isOpen={uploadDocumentModal}
          toggle={() => setUploadDocumentModal(!uploadDocumentModal)}
          backdrop="static"
        >
          <ModalHeader>
            Upload Document
            <span
              className="cross"
              title="Close"
              onClick={() => {
                setUploadDocumentModal(false);
              }}
            >
              x
            </span>
          </ModalHeader>
          <ModalBody>
            <div id="submitDoc">
              {/* <div className="modal-content card custom-modal-container"> */}
              <div className="d-flex">
                <i
                  className="mr-2 fa fa-info-circle fa-2x"
                  aria-hidden="true"
                ></i>
                <p className="info">Important instructions before uploading</p>
              </div>
              <ol>
                <li>
                  Supported file formats are .jpg / .jpeg / .png / .doc / .docx
                  / .pdf/ .tex / .gif.
                </li>
                <li>
                  If you are uploading more than one file please compress all
                  files in a .zip/ .rar format before uploading.
                </li>
                <li>
                  Please select appropriate<strong> Service Type</strong>.
                </li>
                <li>
                  After uploading, keep an eye on the Dashboard for further
                  instructions.
                </li>
                <li>
                  Please note that we will start working on your project as soon
                  as you pay <strong>Token Amount</strong>.
                </li>
                <li>
                  <strong>
                    {" "}
                    Final number of pages may be different from tentative number
                    of pages{" "}
                    <a
                      href="https://api-texscript.getdemos.in/uploads/general_sample.pdf"
                      target="_blank"
                    >
                      (Please refer Sample Document)
                    </a>
                  </strong>
                </li>
              </ol>
              <div className="d-flex file-group">
                <input
                  type="file"
                  className="form-control file-control"
                  name="document_file"
                  required
                  onChange={(e, v) => {
                    setUploadDocument({
                      ...uploadDocument,
                      document_file: e.target.files[0],
                    });
                  }}
                />
              </div>
              <form>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label for="name_of_document">Document Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name_of_document"
                        required
                        onChange={(e, v) => {
                          setUploadDocument({
                            ...uploadDocument,
                            name_of_document: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label for="date">Date of Upload</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled="true"
                        name="date"
                        value={current_date}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label for="service_type">Service type</label>
                      <select
                        className="custom-select"
                        type="select"
                        name="service_type"
                        required
                        onChange={(e, v) => {
                          setUploadDocument({
                            ...uploadDocument,
                            service_type: e.target.value,
                          });
                        }}
                      >
                        <option value="" selected="">
                          Choose Service Type
                        </option>

                        {settings &&
                          settings.prices &&
                          [
                            ...new Set(
                              settings.prices.map((item) => item.service_type)
                            ),
                          ].map((item) => <option>{item}</option>)}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label for="tentative_pages">Uploaded Pages</label>
                      <input
                        type="number"
                        className="form-control"
                        name="tentative_pages"
                        required
                        onChange={(e, v) => {
                          setUploadDocument({
                            ...uploadDocument,
                            tentative_pages: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label for="additional_information">
                    Additional Information (optional)
                  </label>
                  <textarea
                    className="fullWidthTextArea"
                    name="additional_information"
                    rows="4"
                    onChange={(e, v) => {
                      setUploadDocument({
                        ...uploadDocument,
                        additional_information: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>

                {loading ? (
                  <button className="custom-button pull-right cta-submit">
                    Please Wait...
                  </button>
                ) : (
                  <button
                    className="custom-button pull-right cta-submit"
                    onClick={handleUploadDocument}
                  >
                    Confirm
                  </button>
                )}
              </form>
            </div>
            {/* </div> */}
          </ModalBody>
        </Modal>
        {/* Upload Document Modal End */}

        {/* Referral Code Modal Start */}
        <Modal
          size="md"
          isOpen={referralCodeModal}
          toggle={() => setReferralCodeModal(!referralCodeModal)}
          backdrop="static"
        >
          <ModalHeader>
            Referral Code
            <span
              className="cross"
              title="Close"
              onClick={() => {
                setReferralCodeModal(false);
              }}
            >
              x
            </span>
          </ModalHeader>
          <ModalBody>
            <div id="referralCode">
              <div className="d-flex">
                <i
                  className="mr-2 fa fa-info-circle fa-2x"
                  aria-hidden="true"
                ></i>
                <p className="info">
                  This referral code is unique. Share it with your friends to
                  get discounts!
                </p>
              </div>

              <input
                type="text"
                disabled="disabled"
                id="disc-coupon-field"
                className="textBackdrop mt-5 text-center disc-coupon"
                value={user.referral_code}
              />
              <div className="mt-3 d-flex justify-content-end">
                <i className="mr-2 fa fa-clone" aria-hidden="true"></i>
                <p
                  className="copyCode"
                  onClick={() => {
                    var copyText = document.getElementById("disc-coupon-field");

                    copyText.select();
                    copyText.setSelectionRange(
                      0,
                      99999
                    ); /* For mobile devices */

                    navigator.clipboard.writeText(copyText.value);

                    alert("Coupon copied to clipboard.");
                  }}
                >
                  Copy Code
                </p>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*  Referral Code Modal End */}

        {/* Sample Document Modal Start */}
        <Modal
          size="md"
          isOpen={sampleDocumentModal}
          toggle={() => setSampleDocumentModal(!sampleDocumentModal)}
          backdrop="static"
        >
          <ModalHeader>
            General Sample
            <span
              className="cross"
              title="Close"
              onClick={() => {
                setSampleDocumentModal(false);
              }}
            >
              x
            </span>
          </ModalHeader>
          <ModalBody>
            <div id="sampleDoc">
              <div className="d-flex">
                <i
                  className="mr-2 fa fa-info-circle fa-2x"
                  aria-hidden="true"
                ></i>
                <p className="info">
                  This is the general sample document. All the margins and font
                  sizes in this document will be used in every other document.
                </p>
              </div>
              <div className="text-center my-3">
                <a
                  href="https://api-texscript.getdemos.in/uploads/general_sample.pdf"
                  target="_blank"
                  className="sample-doc"
                >
                  <img
                    className="img-fluid sample-image"
                    src={pdf}
                    alt="Click to download"
                  />{" "}
                </a>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*  Sample Document Modal End */}

        {/* Payment Modal Start */}
        <Modal
          size="lg"
          isOpen={paymentInfoModal}
          toggle={() => setPaymentInfoModal(!paymentInfoModal)}
          backdrop="static"
        >
          <ModalHeader>
            Payment Information ({paymentInfo && paymentInfo.document_id})
            <span
              className="cross"
              title="Close"
              onClick={() => {
                setPaymentInfoModal(false);
              }}
            >
              x
            </span>
          </ModalHeader>
          <ModalBody>
            <div id="paymentInfo">
              <div class="row">
                <div class="col">
                  <b> Token Payment Date:</b>
                  <br />
                  {paymentInfo && paymentInfo.payment_info.length > 0
                    ? moment(paymentInfo.payment_info[0].createdAt).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    : "Yet to be paid."}
                </div>
                <div class="col">
                  <b> First Draft Upload Date:</b>
                  <br />
                  {paymentInfo && paymentInfo.first_draft_upload_date
                    ? moment(paymentInfo.first_draft_upload_date).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    : "Yet to be uploaded."}
                </div>
              </div>
              <div class="row">
                <div class="col text-center">
                  <h5 class="textBackdrop">
                    Total Amount :{" "}
                    <span class="pull-right">
                      $
                      {paymentInfo &&
                        (
                          (paymentInfo.amount_paid +
                            paymentInfo.amount_balance +
                            paymentInfo.referral_bonus_used +
                            paymentInfo.coupon.amount) /
                          100
                        ).toFixed(2)}
                    </span>
                  </h5>
                </div>
                <div class="col text-center">
                  <h5 class="textBackdrop">
                    Balance Amount :{" "}
                    <span class="pull-right">
                      $
                      {paymentInfo &&
                        (paymentInfo.amount_balance / 100).toFixed(2)}
                    </span>
                  </h5>
                </div>
              </div>
              {paymentInfo && paymentInfo.payment_info.length > 0 && (
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Date of Payment</th>
                      <th>Amount</th>
                      <th>Order ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentInfo &&
                      paymentInfo.payment_info.map((item) => {
                        return (
                          <tr>
                            <td>
                              {moment(item.createdAt).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                            <td>${(item.amount / 100).toFixed(2)}</td>
                            <td>{item.order_id}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}{" "}
              <div className="col-md-12">
                <Row>
                  <Col md={4}>
                    {paymentInfo &&
                    paymentInfo.coupon &&
                    paymentInfo.coupon.code ? (
                      <>
                        <span>
                          Coupon applied: <b>{paymentInfo.coupon.code}</b>
                          <br />
                          Discount given: <b>{paymentInfo.coupon.discount}%</b>
                          <br />
                          Amount:{" "}
                          <b>${(paymentInfo.coupon.amount / 100).toFixed(2)}</b>
                        </span>
                      </>
                    ) : (
                      <>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Coupon Code"
                          //value={amountToPay}
                          onChange={(e) => {
                            setCoupon(e.target.value);
                          }}
                        />
                        <br />
                        <a
                          class="custom-button pull-right"
                          onClick={handleApplyCoupon}
                        >
                          Apply Coupon
                        </a>
                      </>
                    )}
                  </Col>
                  <Col md={4}>
                    {paymentInfo && paymentInfo.referral_bonus_used > 0 ? (
                      <span>
                        Referral Bonus Used: <br />
                        <b>
                          ${(paymentInfo.referral_bonus_used / 100).toFixed(2)}
                        </b>
                      </span>
                    ) : (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          disabled="true"
                          value={"$" + (user.referral_bonus / 100).toFixed(2)}
                        />
                        <br />
                        <a
                          class="custom-button pull-right"
                          onClick={handlePayFromReferralBonus}
                        >
                          Use Referral Bonus
                        </a>
                        <p style={{ fontSize: "12px" }}>
                          * You can only avail 25 % of your total referral
                          bonus.
                        </p>
                      </>
                    )}
                  </Col>
                  <Col md={4}>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter amount"
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                    />
                    <br />
                    <div style={{ marginTop: "10px" }}>
                      <PayPalScriptProvider
                        options={{
                          "client-id":
                            "ASGh4r6_znIva_NOPHbmVEOwk7Zyao6h1BAxrtr9Sp-LOW4R0HFhC1xtJEFBVytYmnySlr672stNiayF",
                          components: "buttons",
                          currency: currency,
                        }}
                        deferLoading={false}
                      >
                        <PayPalButtons
                          style={style}
                          disabled={false}
                          forceReRender={[amount, currency, style]}
                          fundingSource={undefined}
                          createOrder={(data, actions) => {
                            return actions.order
                              .create({
                                purchase_units: [
                                  {
                                    amount: {
                                      currency_code: currency,
                                      value: amount,
                                    },
                                  },
                                ],
                              })
                              .then((orderId) => {
                                // Your code here after create the order
                                return orderId;
                              });
                          }}
                          onApprove={async (data, actions) => {
                            await actions.order
                              .capture()
                              .then(async (value) => {
                                const values_to_send = {
                                  paper_id: paymentInfo._id,
                                  order_id: data.orderID,
                                  amount: amount,
                                };

                                let url =
                                  process.env.REACT_APP_BASEURL +
                                  "payments/success";
                                const response = await postSubmitForm(
                                  url,
                                  user.token,
                                  values_to_send
                                );

                                setPaymentInfoModal(!paymentInfoModal);
                                loadPapers();
                                window.scrollTo(0, 0);
                                ShowNotification(
                                  "Payment Successful.",
                                  "success"
                                );
                              });
                          }}
                          onClick={async (data, actions) => {
                            if (
                              amount * 100 > paymentInfo.amount_balance ||
                              amount <= 0
                            ) {
                              ShowNotification(
                                "Invalid amount to pay.",
                                "error"
                              );
                              return actions.reject();
                            }
                            return actions.resolve();
                          }}
                        />
                      </PayPalScriptProvider>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*  Payment Modal End */}

        {/* First Draft Reply Start */}
        <Modal
          size="md"
          isOpen={firstDraftReplyModal}
          toggle={() => setFirstDraftReplyModal(!firstDraftReplyModal)}
          backdrop="static"
        >
          <ModalHeader>
            First Draft
            <span
              className="cross"
              title="Close"
              onClick={() => {
                setFirstDraftReplyModal(false);
              }}
            >
              x
            </span>
          </ModalHeader>
          <ModalBody>
            <div cid="docFIRST_DRAFT">
              <div class="d-flex">
                <i class="mr-2 fa fa-info-circle fa-2x" aria-hidden="true"></i>
                <p class="info">
                  This is the first draft of your project.
                  <ul>
                    <li>
                      Please check and suggest for any corrections in Feedback
                      Column or Upload a separate file suggesting required
                      corrections
                    </li>
                    <li>
                      In case of NO correction, please write FINALIZE NOW in
                      Feedback column.
                    </li>
                  </ul>
                </p>
              </div>

              <div class="d-flex file-group">
                <input
                  type="file"
                  class="form-control file-control"
                  name="document_file"
                  onChange={(e, v) => {
                    setSetFirstDraftReplyFile(e.target.files[0]);
                  }}
                />
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-5 my-3">
                  <div class="text-center">
                    <a
                      href={
                        selectedPaper && selectedPaper.first_draft_url_admin
                      }
                      target="_blank"
                      class="draft-download"
                    >
                      <img
                        class="img-fluid sample-image"
                        src={pdf}
                        alt="Click to download"
                      />
                      <br />
                      View/Download
                    </a>
                  </div>
                </div>
                <div class="col-sm-12 col-md-7 my-3">
                  <h6>Admin Reply</h6>
                  <p>{selectedPaper && selectedPaper.additional_information}</p>
                  <h6>Feedback</h6>
                  <textarea
                    class="fullWidthTextArea"
                    rows="3"
                    spellcheck="false"
                    onChange={(e) => {
                      setAdditionalInformation(e.target.value);
                    }}
                  ></textarea>
                  <button
                    class="custom-button cta-submit"
                    onClick={(e, v) => {
                      handleFirstDraftReply(e, v);
                    }}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*   First Draft Reply End */}

        {/* Final Document/File Start */}
        <Modal
          size="md"
          isOpen={finalDocumentFileModal}
          toggle={() => setFinalDocumentFileModal(!finalDocumentFileModal)}
          backdrop="static"
        >
          <ModalHeader>
            Final Document / File
            <span
              className="cross"
              title="Close"
              onClick={() => {
                setFinalDocumentFileModal(false);
              }}
            >
              x
            </span>
          </ModalHeader>
          <ModalBody>
            <div cid="docFIRST_DRAFT">
              <div class="d-flex">
                <i class="mr-2 fa fa-info-circle fa-2x" aria-hidden="true"></i>
                <p class="info">
                  Congratulations! Your final project is ready. Please pay
                  remaining amount to download your Final Project!
                  <br />
                  <ul>
                    <li>Please click on the PDF to download final document.</li>
                    <li>Please click on the File to download final file.</li>
                  </ul>
                </p>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6 my-3">
                  <div class="text-center">
                    <a
                      href={selectedPaper && selectedPaper.final_document_url}
                      target="_blank"
                      className={
                        selectedPaper && selectedPaper.final_document_url
                          ? "draft-download"
                          : "draft-download no-doc"
                      }
                    >
                      <img class="img-fluid" src={pdf} />
                      <br />
                      View/Download
                    </a>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 my-3">
                  <div class="text-center">
                    <a
                      href={selectedPaper && selectedPaper.final_file_url}
                      target="_blank"
                      className={
                        selectedPaper && selectedPaper.final_file_url
                          ? "draft-download"
                          : "draft-download no-doc"
                      }
                    >
                      <img class="img-fluid" src={zip} /> <br />
                      View/Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*   First Document/File End */}
      </section>
    </>
  );
}

export default StudentDashboard;
