import React from 'react'

function RefundsAndCancellationPolicy() {
    return (
        <div class="container-fluid">
            <div className="row">
                <div className="dashboard-container mt-4 mt-44" id="login-bg">
                    <img src='./rc.png' className='responsive img-re' />
                </div>
            </div>


            <div className='row'>
                <div className='col-md-12'>
                    <div className="card-body">
                        <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Refunds and Cancellation Policy</h3>
                        <p>
                            The Website provides email method of contact to facilitate communication between you, us and the writer assigned to complete an order. Our Customer Support is available to you at any time and will respond to any refund request or other issue promptly.</p>

                        <p> The advance payment of “Token Amount” is non-refundable and cannot be refunded in any circumstances if it is less than or equal to 10% of the total estimated service charge. No refund request can be accepted after the delivery of “Final Project”
                            The refund request can only be accepted in any of the following circumstances:</p>

                        <p> 1. If the “Token Amount” paid is more than 10% of the total estimated service charge, then only 10% of the total estimated service charge will be deducted and the remaining amount will be refunded to the client.</p>

                        <p>  2. If the Paper delivery is delayed by more than 24 hours after the offered “Delivery Time” due to unexpected circumstances, a client may raise a refund request, from Our side, We may also provide compensation for the breach of the order deadline in the form of a coupon discount to be used towards your next order with us. Detailed information about “Delivery Time” is given in “Why Us” on Website.</p>

                        <p>   For cancellation of the placed order a client must raise cancellation request by email at  <strong>
                            <a href="mailto:support@texscript.com">support@texscript.com</a>
                        </strong> before the start of “Progress” available on client’s dashboard.</p>
                        <p>&nbsp;</p>


                    </div>
                </div>
            </div>

        </div >
    )
}

export default RefundsAndCancellationPolicy