import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/dashboard.css";
import Papers from "./Papers";

function DeclinedPapers() {
  return <Papers title="Declined Papers" status={["Declined"]} />;
}

export default DeclinedPapers;
