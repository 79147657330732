import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/register.css";
import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { postSubmitFormNoAuth, postSubmitForm } from "../helpers/forms_helper";
import ShowNotification from "../helpers/notification";

function StudentChangePassword() {
  const [update, setUpdate] = useState({ email: "" });
  const [user, setUser] = useState();

  useEffect(() => {
    const u = JSON.parse(localStorage.getItem("user"));
    setUser(u);

    setUpdate({
      email: u.email,
      oldpwd: "",
      newpwd: "",
      confirm_newpwd: "",
    });
  }, []);

  const handleChangePassword = async (e, v) => {
    e.preventDefault();
    console.log(update);

    if (update.newpwd !== update.confirm_newpwd) {
      return ShowNotification("Password confirmation mismatched.", "success");
    }

    let url = process.env.REACT_APP_BASEURL + "students/changepassword";
    let response = await postSubmitForm(url, user.token, update);

    if (response.status === 1) {
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  return (
    <>


      <section class="mt-4 mt-44">

        <div class="dashboard-container py-3" id="login-bg">
          <div class="container-fluid mt-0">
            <h3 class="admin-1">
              <i class="fa fa-unlock" aria-hidden="true">
              </i>&nbsp;Change Password</h3></div>
        </div>
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">

                  <form class="avform">
                    <div className="row form-wrap">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group ">
                          <label for="email"><i class="fa fa-envelope-o" aria-hidden="true"></i>
                            &nbsp;E-mail</label>
                          <input
                            type="email"
                            name="email"
                            className="form-control validated"
                            id="email"
                            value={update.email}
                            disabled={true}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group ">
                          <label for="number"> &nbsp;<i class="fa fa-unlock-alt" aria-hidden="true"></i>&nbsp;Old Password</label>
                          <input
                            type="password"
                            name="mobile"
                            maxLength={15}
                            className="form-control"
                            id="oldpwd"
                            value={update.oldpwd}
                            onChange={(e, v) => {
                              setUpdate({
                                ...update,
                                oldpwd: e.target.value,
                              });
                            }}
                            required="true"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group ">
                          <label for="first_name"> &nbsp;<i class="fa fa-unlock-alt" aria-hidden="true"></i>&nbsp;New Password</label>
                          <input
                            type="password"
                            name="first_name"
                            className="form-control"
                            id="newpwd"
                            value={update.newpwd}
                            onChange={(e, v) => {
                              setUpdate({
                                ...update,
                                newpwd: e.target.value,
                              });
                            }}
                            required="true"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group ">
                          <label for="last_name"> &nbsp;<i class="fa fa-unlock-alt" aria-hidden="true"></i>&nbsp;Confirm New Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="confirm_newpwd"
                            value={update.confirm_newpwd}
                            onChange={(e, v) => {
                              setUpdate({
                                ...update,
                                confirm_newpwd: e.target.value,
                              });
                            }}
                            required="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="action-wrapper">
                      <button
                        type="submit"
                        className="cta cta-fill cta-curvy cta-register"
                        onClick={handleChangePassword}
                      >
                        Update&nbsp;<i class="fa fa-paper-plane-o" aria-hidden="true"></i>

                      </button>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StudentChangePassword;
