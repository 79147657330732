import React from 'react'

function PrivacyPolicy() {
    return (
        <>
            <div class="container-fluid">
                <div className="row">
                    <div className="dashboard-container mt-4 mt-44" id="login-bg">
                        <img src='./pp.png' className='responsive img-re' />
                    </div>
                </div>


                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">

                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Effective from: August 30, 2022</h3>
                            <p>
                                Here at Tex Script (hereinafter “We”) and our website www.texscript.com (hereinafter the “Website”).  We respect your privacy and we are committed to processing personal data of our users/customers in a secure and manner in line with our legal obligations.
                            </p>
                            <p>This Policy explains how We will use any personal data that We may collect about you when you use our Website.</p>
                        </div>
                    </div>
                </div>



                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;1. What data we collect</h3>
                            <p>
                                Our Privacy Policy governs the use and storage of your personal data provided by you at the time of registration.
                            </p>
                            <p>We and our Website development company are the Controller of the personal data you (data subject) provide us. We may collect the following types of personal data from you within the following website:</p>
                            <ol>
                                <li>Contact details such as your email address and phone number;</li>
                                <li>Your highest qualification, country, institution, designation; </li>
                                <li>Data that identifies you such as your IP address, login information, browser type and version, time zone setting, browser plug-in types, geolocation information about where you might be, operating system and version;</li>
                                <li>Data on how you use the website such as your URL clickstreams (the path you take through the website), goods/services viewed, page response times, download errors, how long you stay on webpages, what you do on those pages, how often, and other actions.</li>

                            </ol>
                        </div>
                    </div>
                </div>



                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;2. Why we need it</h3>
                            <p>
                                The Company collects your personal data in order to:
                            </p>

                            <ol>
                                <li>- Provide services;</li>
                                <li>- Keep the website running smoothly;</li>
                                <li>- Improve the performance of website;</li>
                                <li>- Support the customers;</li>
                                <li>- Use it for marketing purposes (with your consent only)</li>
                            </ol>
                            <p>We and our developer’s company are committed not to sell or provide your data to any third party where you have not provided your consent to do so. All other data is processed in accordance with the General Data Protection Regulation (GDPR) 2018 and other applicable laws.</p>
                        </div>
                    </div>
                </div>



                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;3. How Company collects personal data:</h3>
                            <p>
                                The Company collects data when you interact with its website, especially when:
                            </p>
                            <ol>
                                <li>- You browse any page of the website;</li>
                                <li>- Company calls you;;</li>
                                <li>- You use the website;</li>
                                <li>- You receive emails from the Company;</li>
                                <li>- You chat with Company for customer support;</li>
                                <li>- You connect integrations;</li>
                                <li>- You opt-in to marketing emails.</li>
                            </ol>

                        </div>
                    </div>
                </div>



                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;4. What we do with it</h3>
                            <p>
                                Any information collected ordinarily is not disclosed to third parties but may be disclosed in limited circumstances as and if required by law, court order, statutory bodies, rules & regulations or for improving our website or for the security of our network or for any purpose as deemed necessary. Our website contains links to some websites outside our domain that are not controlled by us and are not covered by this privacy policy. We are not responsible for the privacy practices, security or content of such websites.
                            </p>
                        </div>
                    </div>
                </div>



                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;5. How long we keep it</h3>
                            <p>
                                As per the General Data Protection Regulation (GDPR), any personal data must not be kept any longer than necessary for the purpose of which the personal data is processed.
                            </p>
                        </div>
                    </div>
                </div>


                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;6. What are your rights?</h3>
                            <p>
                                You have the right to access to any data that we hold relating to you. Requests must be made in writing and proof of identification is required to protect your data and to ensure it is not disclosed to unauthorized parties.
                            </p>

                            <p>
                                If you believe that any personal data we hold on you is incorrect or incomplete, you have the ability to request to see this data, rectify it or have it deleted.
                            </p>
                            <p>In the event that you wish to complain about how we have handled your personal data, please contact in writing to the Company. We will then look-into your complaint and work with you to resolve the matter.</p>
                        </div>
                    </div>
                </div>


                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;7. Consent rule</h3>
                            <p>
                                If you have given consent to the processing of your data you can freely withdraw such consent at any time by emailing the Company at <b>support@texscript.com</b></p>
                            <p>If you do withdraw your consent, and if the Company does not have another legal basis for the processing of your data, then the Company will stop the processing of your personal data.</p>
                            <p>If the Company has another legal basis for the processing of your data, then the Company may continue to do so subject to your legal interests and rights.
                            </p>
                        </div>
                    </div>
                </div>



                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;8. Company’s responsibilities</h3>
                            <p>
                                If you are a registered user or a visitor to the Website the Company acts as the ‘data controller’ of personal data. This means that the Company determines how and why your data are processed.
                            </p>
                        </div>
                    </div>
                </div>


                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;9. Your responsibilities</h3>
                            <p>
                                If you provide the Company with personal data about other individuals, the Company will only employ that data for the special reason for which it was provided to the Company. By sending the data, you confirm that you have the right to dispose to process the data on your behalf in accordance with this Privacy Policy.</p>
                            <p>Read this Privacy Policy carefully and treat your personal data confidential and secure.

                            </p>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp; 10. Security
                            </h3>
                            <p>
                                We have security and organizational measures and procedures to secure the data collected and stored. We have security policies and data processing agreements with all our employees and contractors who are obliged to follow and maintain appropriate technical and organizational measures.
                            </p>

                            <p>You acknowledge that no perfect security infrastructure exists, no data transmission is guaranteed to be 100% secure, and there may be some security risks.</p>
                            <p>You are responsible for your login information and password. You shall keep them confidential.</p>
                            <p>In case if your privacy has been breached, please contact the Company immediately on support@texscript.com</p>

                        </div>
                    </div>
                </div>


                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp; 11. Location of the processing of personal data
                            </h3>
                            <p>
                                The personal data collected by the Company is processed at the Company’s offices. The Company is international and can have foreign branches and departments. The Company’s Research and Development department is based on secure premises. The employees and contractors of the Company’s Research and Development department are bound by the respective agreements, Company’s internal policies and Information Security Policy.</p>

                        </div>
                    </div>
                </div>


                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp; 12. Right to Request Deletion
                            </h3>
                            <p>
                                You have the right to request that the Company delete any of your personal data that the Company collected about you and retained. The Company will delete your personal data from our records once the Company receives your request and verifies your personality.</p>

                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;13. Cookies
                            </h3>
                            <p>
                                Cookies are pieces of data that a Website transfers to a user's hard drive for record-keeping purposes.
                            </p>
                            <p>The Company uses cookies to provide proper functionality on the Website and aggregate traffic data (e.g., what pages are popular)The Company may use ever cookies with reference to Legitimate interest only.
                            </p>
                            <p><strong>The Company uses the following types of cookies:</strong></p>
                            <ol>
                                <li>	Strictly needed cookies. These cookies are necessary for the Website to work correctly. They will allow you to move our Website and use its capabilities. These files do not identify you as a person. If you do not agree to use this type of file this may affect the proper work of the Website or its components.</li>
                                <li>	Cookies related to performance, efficiency, and analytics. These files help us understand how you interact with our Website by providing information about the areas visited and the amount of time spent on the Website, as well as showing problems in the operation of the Internet resource, for example, error messages. This will help us to improve the work of the Website.</li>
                                <li>	Cookies related to analytics. These files help us to measure the effectiveness of advertising campaigns and optimize the content of Websites for those who are interested in our advertising. This type of cookies shall not be used for Your identification. All information that is collected and analyzed is anonymous.</li>
                                <li>	Advertising cookies. These cookies record information about Your online activities, including visits to our Website, as well as information about the links and advertising that you have chosen to view. The Company uses such files to collect data about your activity on the Internet and determine your interests, which allows the Company to provide advertising that suits your interests and on which you have given your consent.</li>


                            </ol>
                            <p>The Website capture limited data (user-agent, HTTP referrer, last URL requested by the user, client-side and server-side clickstream) about visits to the Website. The Company may use this data to analyze patterns and to perform system maintenance. You have several options how to manage cookies on your device. All browsers allow you to block or delete cookies from your device. You may consult the privacy features in your browser to understand what you should do if you need to manage cookies.</p>
                        </div>
                    </div>
                </div>



                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;14. Contact details of the Data Controller
                            </h3>
                            <p>
                                <strong>
                                    Email: <a href="mailto:support@texscript.com">support@texscript.com</a>
                                </strong>
                            </p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>



            </div >
        </>
    )
}

export default PrivacyPolicy