import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/register.css";
import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { postSubmitFormNoAuth, postSubmitForm } from "../helpers/forms_helper";
import ShowNotification from "../helpers/notification";
import { UserContext } from "./LoginContext";

function Register() {
  const { setUser } = useContext(UserContext);

  const navigate = useNavigate();

  const [isOtpTimer, setIsOtpTimer] = useState(false);

  const startOtpTimer = () => {
    let otpTimer = 60;
    setIsOtpTimer(true);
    const otp = setInterval(() => {
      const elem = document.getElementById("otp_button");
      if (otpTimer <= 1) {
        clearInterval(otp);
        setIsOtpTimer(false);

        if (elem) elem.innerText = "Resend OTP";
      } else {
        otpTimer -= 1;

        if (elem) elem.innerText = "Resend in " + otpTimer;
      }
    }, 1000);
  };

  const [register, setRegister] = useState({
    first_name: "",
    last_name: "",
    email: "",
    pwd: "",
    confirmpwd: "",
    country: "",
    designation: "",
    institute: "",
    highest_degree: "",
    mobile: "",
    otp: "",
    referred_by: "",
  });

  const [emailStatus, setEmailStatus] = useState();

  const handleCheckEmail = async () => {
    if (
      register.email === "" ||
      !register.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      setEmailStatus({
        status: "error",
        otp: "pending",
        otp_value: "",
        color: "Red",
        message: "Invalid email.",
      });
      return;
    }
    let url = process.env.REACT_APP_BASEURL + "students/checkemail";
    let response = await postSubmitFormNoAuth(url, {
      email: register.email,
    });

    if (response.status === 1) {
      setEmailStatus({
        status: "available",
        otp: "pending",
        otp_value: "",
        color: "Green",
        message: response.message,
      });
    } else {
      setEmailStatus({
        status: "exists",
        otp: "pending",
        otp_value: "",
        color: "Red",
        message: response.message,
      });
    }
  };

  const handleSendOTP = async () => {
    startOtpTimer();
    let url = process.env.REACT_APP_BASEURL + "students/sendOTP";
    let response = await postSubmitFormNoAuth(url, {
      email: register.email,
    });

    if (response.status === 1) {
      setEmailStatus({
        status: "available",
        otp: "sent",
        otp_value: response.data.otp,
        color: "Green",
        message: response.message,
      });


    } else {
      setEmailStatus({
        status: "exists",
        otp: "error",
        otp_value: "",
        color: "Red",
        message: response.message,
      });
    }
  };

  const handleRegister = async (e, v) => {
    e.preventDefault();

    if (!emailStatus) {
      return ShowNotification("Invalid Email", "error");
    }

    if (emailStatus && emailStatus.status !== "available") {
      return ShowNotification("Invalid Email", "error");
    }

    if (!register.email) {
      return ShowNotification("Email cannot be blank.", "error");
    }
    if (!register.first_name) {
      return ShowNotification("First Name cannot be blank.", "error");
    }

    if (!register.last_name) {
      return ShowNotification("Last Name cannot be blank.", "error");
    }

    if (!register.country) {
      return ShowNotification("Country cannot be blank.", "error");
    }

    if (!register.designation) {
      return ShowNotification("Designation cannot be blank.", "error");
    }

    if (!register.institute) {
      return ShowNotification("Institute cannot be blank.", "error");
    }

    if (!register.highest_degree) {
      return ShowNotification("Highest Degree cannot be blank.", "error");
    }

    if (!register.mobile) {
      return ShowNotification("Mobile cannot be blank.", "error");
    }
    if (!register.otp || register.otp !== emailStatus.otp_value.toString()) {
      return ShowNotification("Invalid OTP", "error");
    }

    if (!register.pwd) {
      return ShowNotification("Password cannot be blank.", "error");
    }

    if (register.pwd !== register.confirmpwd) {
      return ShowNotification("Password confirm mismatched.", "error");
    }
    let url = process.env.REACT_APP_BASEURL + "students/register";
    let response = await postSubmitFormNoAuth(url, register);

    if (response.status === 1) {
      localStorage.setItem("user", JSON.stringify(response.data));
      setUser(response.data);
      navigate("/student-dashboard");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  // useEffect(() => {
  //   console.log(emailStatus);
  // }, [emailStatus]);

  return (
    <>

      <section class="mt-4 mt-44">
        <div class="dashboard-container py-3" id="login-bg">
          <div class="container-fluid mt-0">
            <h3 class="admin-1">
              <i class="fa fa-sign-in" aria-hidden="true"></i>
              &nbsp;Registration</h3></div>
        </div>

        <div class="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <form class="avform">
                    <div className="row form-wrap">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label for="email">E-mail</label>
                          <input
                            type="email"
                            name="email"
                            className="form-control validated"
                            id="email"
                            onChange={(e, v) => {
                              setRegister({ ...register, email: e.target.value });
                            }}
                            onBlur={handleCheckEmail}
                            disabled={
                              emailStatus && emailStatus.otp === "sent"
                                ? true
                                : false
                            }
                            required
                          />
                          {emailStatus && (
                            <p style={{ color: emailStatus.color }}>
                              {emailStatus.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="row">
                          <div className="col-sm-12 col-md-6  d-flex align-items-end mb-3 otp-wrap">
                            <a
                              href="javascript:void(0)"
                              id="otp_button"
                              className={
                                emailStatus &&
                                  emailStatus.status === "available" &&
                                  isOtpTimer === false
                                  ? "cta cta-fill cta-otp"
                                  : "cta cta-fill cta-otp disable-btn"
                              }
                              style={{ textAlign: "center", width: "100%" }}
                              onClick={handleSendOTP}
                            >
                              Send OTP
                            </a>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                              <label for="otp">OTP</label>
                              <input
                                type="text"
                                name="otp"
                                className="form-control"
                                disabled={
                                  emailStatus && emailStatus.otp === "sent"
                                    ? false
                                    : true
                                }
                                id="otp"
                                onChange={(e, v) => {
                                  setRegister({
                                    ...register,
                                    otp: e.target.value,
                                  });
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label for="password">Password</label>
                          <input
                            type="password"
                            name="pwd"
                            className="form-control validated"
                            id="pwd"
                            onChange={(e, v) => {
                              setRegister({ ...register, pwd: e.target.value });
                            }}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label for="password">Confirm Password</label>
                          <input
                            type="password"
                            name="confirmpwd"
                            className="form-control validated"
                            id="confirmpwd"
                            onChange={(e, v) => {
                              setRegister({
                                ...register,
                                confirmpwd: e.target.value,
                              });
                            }}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group ">
                          <label for="first_name">First Name</label>
                          <input
                            type="text"
                            name="first_name"
                            className="form-control"
                            id="first_name"
                            onChange={(e, v) => {
                              setRegister({
                                ...register,
                                first_name: e.target.value,
                              });
                            }}
                            required="true"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group ">
                          <label for="last_name">Last Name</label>
                          <input
                            type="last_name"
                            name="last_name"
                            className="form-control"
                            id="last_name"
                            onChange={(e, v) => {
                              setRegister({
                                ...register,
                                last_name: e.target.value,
                              });
                            }}
                            required="true"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label for="designation">Designation</label>
                          <input
                            type="text"
                            name="designation"
                            className="form-control"
                            id="designation"
                            onChange={(e, v) => {
                              setRegister({
                                ...register,
                                designation: e.target.value,
                              });
                            }}
                            required="true"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label for="number">Mobile Number</label>
                          <input
                            type="number"
                            name="mobile"
                            maxLength={15}
                            className="form-control"
                            id="number"
                            onChange={(e, v) => {
                              setRegister({
                                ...register,
                                mobile: e.target.value,
                              });
                            }}
                            required="true"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label for="degree">Highest Degree</label>
                          <input
                            type="text"
                            name="highest_degree"
                            className="form-control"
                            id="highest_degree"
                            onChange={(e, v) => {
                              setRegister({
                                ...register,
                                highest_degree: e.target.value,
                              });
                            }}
                            required="true"
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label for="institue">Institute</label>
                          <input
                            type="text"
                            name="institute"
                            className="form-control"
                            id="institute"
                            onChange={(e, v) => {
                              setRegister({
                                ...register,
                                institute: e.target.value,
                              });
                            }}
                            required="true"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group ">
                          <label for="degree">Country </label>
                          <select
                            className="custom-select"
                            type="select"
                            name="country"
                            required
                            onChange={(e, v) => {
                              setRegister({
                                ...register,
                                country: e.target.value,
                              });
                            }}
                          >
                            <option value="">--Select Country--</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua">Antigua</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijani">Azerbaijani</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Netherlands">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia-Hercegovina">
                              Bosnia-Hercegovina
                            </option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British IOT">British IOT</option>
                            <option value="Brunei Darussalam">
                              Brunei Darussalam
                            </option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Rep">
                              Central African Rep
                            </option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">
                              Christmas Island
                            </option>
                            <option value="Cocos Islands">Cocos Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">
                              Dominican Republic
                            </option>
                            <option value="East Timor">East Timor</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">
                              Equatorial Guinea
                            </option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Faeroe Islands">Faeroe Islands</option>
                            <option value="Falkland Islands">
                              Falkland Islands
                            </option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">
                              French Polynesia
                            </option>
                            <option value="French Southern">French Southern</option>
                            <option value="French Southern Ter">
                              French Southern Ter
                            </option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard">Heard</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Ivory Coast">Ivory Coast</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Laos">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">
                              Marshall Islands
                            </option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia">Micronesia</option>
                            <option value="MNP">MNP</option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="NER">NER</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Neutral Zone">Neutral Zone</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="North Korea">North Korea</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">
                              Papua New Guinea
                            </option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Helena">Saint Helena</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Pierre">Saint Pierre</option>
                            <option value="Saint Vincent">Saint Vincent</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Scotland">Scotland</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovak Republic">Slovak Republic</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somali Democratic">
                              Somali Democratic
                            </option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia">South Georgia</option>
                            <option value="South Korea">South Korea</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="St. Kitts and Nevis">
                              St. Kitts and Nevis
                            </option>
                            <option value="STP">STP</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard">Svalbard</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="TCA">TCA</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Toga">Toga</option>
                            <option value="Togolese">Togolese</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tongo">Tongo</option>
                            <option value="Trinidad and Tobago">
                              Trinidad and Tobago
                            </option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab">United Arab</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="Upper Volta">Upper Volta</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="USSR(Former)">USSR(Former)</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City">Vatican City</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="VI, British">VI, British</option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands, USA">
                              Virgin Islands, USA
                            </option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="WLF">WLF</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Yugoslavia">Yugoslavia</option>
                            <option value="Zaire">Zaire</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group ">
                          <label for="institue">Referral Code</label>
                          <input
                            type="text"
                            name="referral_code"
                            className="form-control"
                            id="referral_code"
                            placeholder="optional"
                            onChange={(e, v) => {
                              setRegister({
                                ...register,
                                referred_by: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="action-wrapper">
                      <button
                        type="submit"
                        className="cta cta-fill cta-curvy cta-register"
                        onClick={handleRegister}
                      >
                        Register
                      </button>
                    </div>
                    <p className=" text-center bottom-text abc">
                      Already Registered? <a href="login">Log In</a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default Register;
