import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/dashboard.css";
import { useEffect, useState } from "react";
import {
  postSubmitForm,
  postSubmitForm_withformdata,
  postSubmitFormNoAuth,
} from "../helpers/forms_helper";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import ShowNotification from "../helpers/notification";
import moment from "moment";
import yes from "../assets/icons/check-solid.svg";
import no from "../assets/icons/times-solid.svg";
import pdf from "../assets/icons/pdf-icon.png";
import exportFromJSON from "export-from-json";
function Papers(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const [papers, setPapers] = useState([]);
  const [settings, setSettings] = useState(null);
  const [documenttype, setDocumentType] = useState("");
  const [filteredPapers, setFilteredPapers] = useState([]);

  const [filterFields, setFilterFields] = useState({
    document_type: "",
    document_id: "",
    name_of_document: "",
    service_type: "",
    email: "",
  });

  const [sortFields, setSortFields] = useState({
    createdAt: 1,
    delivery_date: 1,
  });

  useEffect(() => {
    loadPapers();
    loadSettings();
  }, []);

  useEffect(() => {
    if (documenttype === "All Work") setFilteredPapers(papers);
    else {
      let arr = papers.filter(
        (papers) => papers.document_type === documenttype
      );
      setFilteredPapers(arr);
    }
  }, [documenttype]);

  const applyFilter = () => {
    let funnel = JSON.parse(JSON.stringify(papers));

    // filter for document type
    if (filterFields.document_type !== "") {
      funnel = funnel.filter(
        (paper) =>
          paper.document_id.charAt(0) === filterFields.document_type.charAt(0)
      );
    }
    // filter for document_id
    if (filterFields.document_id !== "") {
      funnel = funnel.filter((paper) =>
        paper.document_id
          .toLowerCase()
          .includes(filterFields.document_id.toLowerCase())
      );
    }

    // filter for name_of_document
    if (filterFields.name_of_document !== "") {
      funnel = funnel.filter((paper) =>
        paper.name_of_document
          .toLowerCase()
          .includes(filterFields.name_of_document.toLowerCase())
      );
    }

    // filter for service_type
    if (filterFields.service_type !== "") {
      funnel = funnel.filter((paper) =>
        paper.service_type
          .toLowerCase()
          .includes(filterFields.service_type.toLowerCase())
      );
    }

    // filter for email
    if (filterFields.email !== "") {
      funnel = funnel.filter((paper) =>
        paper.student_details.email
          .toLowerCase()
          .includes(filterFields.email.toLowerCase())
      );
    }

    setFilteredPapers(funnel);
  };

  useEffect(() => {
    applyFilter();
  }, [filterFields]);

  const loadPapers = async () => {
    console.log(props.status);
    let url = process.env.REACT_APP_BASEURL + "papers/getall";
    let response = await postSubmitForm(url, user.token, {
      status: props.status,
    });
    console.log(response);
    if (response.status === 1) {
      setPapers(response.data);
      setFilteredPapers(response.data);
    } else {
      ShowNotification(response.message, "Error");
    }
  };

  const loadSettings = async () => {
    let url = process.env.REACT_APP_BASEURL + "settings/get";
    let response = await postSubmitForm(url, user.token, {});

    if (response.status === 1) {
      setSettings(response.data);
    } else {
      ShowNotification(response.message, "Error");
    }
  };

  const [paymentInfoModal, setPaymentInfoModal] = useState(false);
  const [firstDraftModal, setFirstDraftModal] = useState(false);
  const [firstDraftFeedbackModal, setFirstDraftFeedbackModal] = useState(false);
  const [finalDocumentModal, setFinalDocumentModal] = useState(false);

  const [finalFileModal, setFinalFileModal] = useState(false);

  const [selectedPaper, setSelectedPaper] = useState(null);

  const [paymentInfo, setPaymentInfo] = useState();
  const [firstDraftFile, setSetFirstDraftFile] = useState(null);
  const [finalDocumentFile, setFinalDocumentFile] = useState(null);
  const [finalFile, setFinalFile] = useState(null);

  const [additionalInformation, setAdditionalInformation] = useState("");

  const options = [
    {
      label: "All Work",
      value: "",
    },
    { label: "Regular", value: "Regular" },
    {
      label: "Urgent",
      value: "Urgent",
    },
    {
      label: "Delayed",
      value: "Delayed",
    },
  ];
  const handlePaperStatus = async (id, status) => {
    //console.log("paper status");
    let url = process.env.REACT_APP_BASEURL + "papers/paper_status";

    let response = await postSubmitForm(url, user.token, { id, status });
    console.log(response);
    if (response.status === 1) {
      loadPapers();
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "Error");
    }
  };

  const handleUpdateTentativePages = async (id, updatedTentativePages) => {
    console.log(updatedTentativePages);
    //console.log("paper status");
    let url = process.env.REACT_APP_BASEURL + "papers/tentativepages";

    let response = await postSubmitForm(url, user.token, {
      id,
      tentative_pages: updatedTentativePages,
    });

    if (response.status === 1) {
      ShowNotification("Tentative pages updated successfully", "success");
      loadPapers();
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handleUpdateProgress = async (id, progress) => {
    let url = process.env.REACT_APP_BASEURL + "papers/progress";

    let response = await postSubmitForm(url, user.token, {
      id,
      progress,
    });

    if (response.status === 1) {
      ShowNotification(response.message, "success");
      loadPapers();
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handleFirstDraft = async (e, v) => {
    e.preventDefault();

    if (firstDraftFile === null) {
      return ShowNotification("Please select a file to upload.", "error");
    }

    let formData = new FormData();

    formData.set("id", selectedPaper._id);
    formData.set("additional_information", additionalInformation);
    formData.append("document_file", firstDraftFile);

    let url = process.env.REACT_APP_BASEURL + "papers/firstdraft";
    let response = await postSubmitForm_withformdata(url, user.token, formData);
    if (response.status === 1) {
      loadPapers();
      setFirstDraftModal(false);
      setFirstDraftFeedbackModal(false);
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const [progress, setProgress] = useState(0);
  const [finalPages, setFinalPages] = useState(0);

  const handleFinalDocument = async (e, v) => {
    e.preventDefault();

    if (finalDocumentFile === null) {
      return ShowNotification("Please select a file to upload.", "error");
    }

    let formData = new FormData();

    formData.set("id", selectedPaper._id);
    formData.set("progress", progress);
    formData.set("final_pages", finalPages);
    formData.append("document_file", finalDocumentFile);

    let url = process.env.REACT_APP_BASEURL + "papers/finaldocument";
    let response = await postSubmitForm_withformdata(url, user.token, formData);
    if (response.status === 1) {
      loadPapers();
      setFinalDocumentModal(false);
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handleFinalFile = async (e, v) => {
    e.preventDefault();
    console.log(finalFile);

    if (finalFile === null) {
      return ShowNotification("Please select a file to upload.", "error");
    }

    let formData = new FormData();

    formData.set("id", selectedPaper._id);
    formData.append("document_file", finalFile);

    let url = process.env.REACT_APP_BASEURL + "papers/finalfile";
    let response = await postSubmitForm_withformdata(url, user.token, formData);
    if (response.status === 1) {
      loadPapers();
      setFinalFileModal(false);
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handleDownload = async () => {
    const fileName = "user_report";
    const exportType = "xls";
    var data = JSON.parse(JSON.stringify(filteredPapers));
    data.forEach(function (v) {
      v.email = v.student_details.email;
      v.uoload_date = moment(v.createdAt).format("DD/MM/YYYY");
      v.delivery =
        v.payment_info.length === 0
          ? ""
          : v.document_type === "Regular" && v.document_id.charAt(0) === "R"
          ? moment(v.createdAt).add(4, "days").format("DD/MM/YYYY")
          : v.document_type === "Urgent" && v.document_id.charAt(0) === "U"
          ? moment(v.createdAt).add(2, "days").format("DD/MM/YYYY")
          : v.document_type === "Regular" && v.document_id.charAt(0) === "D"
          ? moment(v.createdAt).add(7, "days").format("DD/MM/YYYY")
          : v.document_type === "Urgent" && v.document_id.charAt(0) === "D"
          ? moment(v.createdAt).add(3, "days").format("DD/MM/YYYY")
          : "";

      delete v._id;
      delete v.student_details;
      delete v.first_draft_upload_date;
      delete v.first_draft_url_admin;
      delete v.amount_paid;
      delete v.action_required_by;
      delete v.first_draft_reply_url;
      delete v.first_draft_url_admian;
      delete v.amount_balance;
      delete v.payment_info;
      delete v.updatedAt;
      delete v.__v;
      delete v.tentative_page_status;
      delete v.final_file_uploaded;
      delete v.final_document_uploaded;
      delete v.first_draft_reply_uploaded;
      delete v.first_draft_uploaded;
      delete v.coupon;
    });
    exportFromJSON({ data, fileName, exportType });
  };

  function dynamicSort(property) {
    const sortOrder = sortFields[property];
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const handleSorting = async (e, sortField) => {
    e.preventDefault();
    console.log("handleSorting");
    const arr = JSON.parse(JSON.stringify(filteredPapers));
    //console.log(sortFields[sortField]);
    if (sortFields[sortField] === 1)
      setSortFields({ ...sortFields, [sortField]: -1 });
    else setSortFields({ ...sortFields, [sortField]: 1 });

    arr.sort(dynamicSort(sortField));

    setFilteredPapers(arr);
  };

  return (
    <>
      <section className="mt-4 mt-44">
        <div className="container-fluid">
          <div className="dashboard-container py-3" id="login-bg">
            <div className="d-flex justify-content-between align-items-center">
              <div className="container-fluid mt-0">
                <div className="row">
                  <div className="left">
                    <h3 className="admin-1">
                      {" "}
                      <i class="fa fa-user" aria-hidden="true"></i>
                      &nbsp;{props.title}
                    </h3>
                  </div>

                  <div className="right">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setFilterFields({
                          ...filterFields,
                          document_type: e.target.value,
                        });
                      }}
                    >
                      {options.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row className="mt-2">
            <Col sm="4">
              <button
                type="submit"
                className="btn-red"
                onClick={handleDownload}
              >
                <i className="mdi mdi-file-export"></i> {"Export CSV"}
                &nbsp;&nbsp;<i class="fa fa-file-pdf-o" aria-hidden="true"></i>
              </button>
            </Col>
          </Row>
          <div className="card mb-2">
            <div className="card-body">
              <div className="table-responsive  admin-dashboard">
                <table class="table table-striped ">
                  <thead className="table-bg">
                    <tr>
                      <th>
                        Document ID
                        <br />
                        <input
                          type="text"
                          size={5}
                          onChange={(e) => {
                            setFilterFields({
                              ...filterFields,
                              document_id: e.target.value,
                            });
                          }}
                        />
                      </th>
                      <th>
                        Name of Document
                        <br />
                        <input
                          type="text"
                          size={10}
                          onChange={(e) => {
                            setFilterFields({
                              ...filterFields,
                              name_of_document: e.target.value,
                            });
                          }}
                        />
                      </th>
                      <th>
                        <a
                          href="#"
                          onClick={(e) => {
                            handleSorting(e, "createdAt");
                          }}
                        >
                          Upload Date
                        </a>{" "}
                        {sortFields.createdAt === 1 ? "\u2193" : "\u2191"}
                      </th>
                      <th>
                        <a
                          href="#"
                          onClick={(e) => {
                            handleSorting(e, "delivery_date");
                          }}
                        >
                          Delivery Date
                        </a>{" "}
                        {sortFields.delivery_date === 1 ? "\u2193" : "\u2191"}
                      </th>
                      <th>Progress</th>
                      <th>Tentative Pages</th>
                      <th>Final Pages</th>
                      <th>
                        Service Type
                        <br />
                        <select
                          //className="custom-select country"
                          type="select"
                          name="service_type"
                          style={{ width: "150px" }}
                          required
                          onChange={(e, v) => {
                            setFilterFields({
                              ...filterFields,
                              service_type: e.target.value,
                            });
                          }}
                        >
                          <option value="">All</option>
                          {settings &&
                            settings.prices &&
                            [
                              ...new Set(
                                settings.prices.map((item) => item.service_type)
                              ),
                            ].map((item) => <option>{item}</option>)}
                        </select>
                      </th>
                      <th>
                        Email
                        <br />
                        <input
                          type="text"
                          onChange={(e) => {
                            setFilterFields({
                              ...filterFields,
                              email: e.target.value,
                            });
                          }}
                        />
                      </th>
                      <th>Status</th>
                      <th scope="col">Payment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPapers &&
                      filteredPapers.map((item) => {
                        return (
                          <tr>
                            {/* original document */}
                            <th
                              className={
                                item.action_required_by === "Admin"
                                  ? "notify"
                                  : ""
                              }
                            >
                              <a
                                href={item.original_document_url}
                                target="_blank"
                              >
                                {item.document_id}
                              </a>
                            </th>
                            {/* name of document */}
                            <td style={{ textAlign: "left" }}>
                              {item.name_of_document}
                            </td>
                            {/* upload date */}
                            <td>
                              {moment(item.createdAt).format("DD/MM/YYYY")}
                            </td>
                            {/* delivery date */}
                            <td>
                              {item.delivery_date}
                              {/* {item.payment_info.length === 0
                                ? ""
                                : item.document_type === "Regular" &&
                                  item.document_id.charAt(0) === "R"
                                ? moment(item.createdAt)
                                    .add(4, "days")
                                    .format("DD/MM/YYYY")
                                : item.document_type === "Urgent" &&
                                  item.document_id.charAt(0) === "U"
                                ? moment(item.createdAt)
                                    .add(2, "days")
                                    .format("DD/MM/YYYY")
                                : item.document_type === "Regular" &&
                                  item.document_id.charAt(0) === "D"
                                ? moment(item.createdAt)
                                    .add(7, "days")
                                    .format("DD/MM/YYYY")
                                : item.document_type === "Urgent" &&
                                  item.document_id.charAt(0) === "D"
                                ? moment(item.createdAt)
                                    .add(3, "days")
                                    .format("DD/MM/YYYY")
                                : ""} */}
                            </td>
                            {/* progress */}
                            {item.status === "Final" ||
                            item.status === "Declined" ? (
                              <td>{item.progress} %</td>
                            ) : (
                              <td className="editable editable-field">
                                <div>
                                  <input
                                    type="number"
                                    className="inline-field"
                                    defaultValue={item.progress}
                                    onBlur={(e) => {
                                      handleUpdateProgress(
                                        item._id,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  %
                                </div>
                              </td>
                            )}
                            {/* tentative pages */}
                            {item.tentative_page_status === true ||
                            item.status === "Declined" ||
                            item.status === "Final" ? (
                              <td> {item.tentative_pages}</td>
                            ) : (
                              <td className="editable editable-field">
                                <div>
                                  <input
                                    type="number"
                                    className="inline-field"
                                    defaultValue={item.tentative_pages}
                                    onBlur={(e) => {
                                      handleUpdateTentativePages(
                                        item._id,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </td>
                            )}
                            {/* final pages */}
                            <td>{item.final_pages}</td>
                            {/* student email */} {/* serviceType */}
                            <td style={{ textAlign: "left" }}>
                              {item.service_type}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {item.student_details.email}
                            </td>
                            {/* status   */}
                            <td>
                              <b>
                                {item.status === "Accepted" &&
                                item.tentative_page_status === true ? (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setSelectedPaper(item);
                                      setFirstDraftModal(!firstDraftModal);
                                    }}
                                  >
                                    {item.status}
                                  </a>
                                ) : item.status === "First Draft" &&
                                  item.first_draft_reply_uploaded === true ? (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setSelectedPaper(item);
                                      setFirstDraftFeedbackModal(
                                        !firstDraftFeedbackModal
                                      );
                                    }}
                                  >
                                    {item.status}
                                  </a>
                                ) : item.status === "Final" ||
                                  item.status === "Final Document" ? (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setSelectedPaper(item);
                                      setFinalFileModal(!finalFileModal);
                                    }}
                                  >
                                    {item.status}
                                  </a>
                                ) : (
                                  item.status
                                )}
                              </b>
                              {item.status === "Pending" ? (
                                <>
                                  <br />
                                  <img
                                    class="icon ml-1"
                                    src={yes}
                                    title="Accept"
                                    onClick={() => {
                                      handlePaperStatus(item._id, "Accepted");
                                    }}
                                  />{" "}
                                  <img
                                    class="icon ml-1"
                                    src={no}
                                    title="Reject"
                                    onClick={() => {
                                      handlePaperStatus(item._id, "Declined");
                                    }}
                                  />
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            {/* payment info */}
                            <td>
                              {item.amount_balance > 0 &&
                              item.payment_info.length > 0 ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPaymentInfo(item);
                                    setPaymentInfoModal(!paymentInfoModal);
                                  }}
                                >
                                  Partially Paid
                                </span>
                              ) : item.amount_balance > 0 &&
                                item.payment_info.length === 0 ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPaymentInfo(item);
                                    setPaymentInfoModal(!paymentInfoModal);
                                  }}
                                >
                                  Due
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setPaymentInfo(item);
                                    setPaymentInfoModal(!paymentInfoModal);
                                  }}
                                >
                                  Paid
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between custom-inner-container">
            <p className="text count">
              Total Count: {filteredPapers && filteredPapers.length}
            </p>
            <div className="custom-pagination d-flex align-items-center">
              <i className="fa fa-angle-left" aria-hidden="true">
                {"<"}
              </i>
              <p className="text-small pagination">1-15</p>
              <i className="fa fa-angle-right" aria-hidden="true">
                {">"}
              </i>
            </div>
          </div>
        </div>
      </section>

      {/* Payment Modal Start */}
      <Modal
        size="lg"
        isOpen={paymentInfoModal}
        toggle={() => setPaymentInfoModal(!paymentInfoModal)}
        backdrop="static"
      >
        <ModalHeader>
          Payment Information ({paymentInfo && paymentInfo.document_id})
          <span
            className="cross"
            title="Close"
            onClick={() => {
              setPaymentInfoModal(false);
            }}
          >
            x
          </span>
        </ModalHeader>
        <ModalBody>
          <div id="paymentInfo">
            <div class="row">
              <div class="col">
                <b> Token Payment Date:</b>
                <br />
                {paymentInfo && paymentInfo.payment_info.length > 0
                  ? moment(paymentInfo.payment_info[0].createdAt).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : "Yet to be paid."}
              </div>
              <div class="col">
                <b> First Draft Upload Date:</b>
                <br />
                {paymentInfo && paymentInfo.first_draft_upload_date
                  ? moment(paymentInfo.first_draft_upload_date).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : "Yet to be uploaded."}
              </div>
            </div>
            <div class="row">
              <div class="col text-center">
                <h5 class="textBackdrop">
                  Total Amount :{" "}
                  <span class="pull-right">
                    $
                    {paymentInfo &&
                      (
                        (paymentInfo.amount_paid +
                          paymentInfo.amount_balance +
                          paymentInfo.referral_bonus_used +
                          paymentInfo.coupon.amount) /
                        100
                      ).toFixed(2)}
                  </span>
                </h5>
              </div>
              <div class="col text-center">
                <h5 class="textBackdrop">
                  Balance Amount :{" "}
                  <span class="pull-right">
                    $
                    {paymentInfo &&
                      (paymentInfo.amount_balance / 100).toFixed(2)}
                  </span>
                </h5>
              </div>
            </div>
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Date of Payment</th>
                  <th>Amount</th>
                  <th>Order ID</th>
                </tr>
              </thead>
              <tbody>
                {paymentInfo &&
                  paymentInfo.payment_info.map((item) => {
                    return (
                      <tr>
                        <td>
                          {moment(item.createdAt).format("DD/MM/YYYY HH:mm")}
                        </td>
                        <td>${item.amount / 100}</td>
                        <td>{item.order_id}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="col-md-12">
              <Row>
                <Col md={6}>
                  {paymentInfo &&
                  paymentInfo.coupon &&
                  paymentInfo.coupon.code ? (
                    <>
                      <span>
                        Coupon applied: <b>{paymentInfo.coupon.code}</b>
                        <br />
                        Discount given: <b>{paymentInfo.coupon.discount}%</b>
                        <br />
                        Amount:{" "}
                        <b>${(paymentInfo.coupon.amount / 100).toFixed(2)}</b>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={6}>
                  {paymentInfo && paymentInfo.referral_bonus_used > 0 ? (
                    <span>
                      Referral Bonus Used: <br />
                      <b>
                        ${(paymentInfo.referral_bonus_used / 100).toFixed(2)}
                      </b>
                    </span>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/*  Payment Modal End */}

      {/* First Draft Modal Start */}
      <Modal
        size="md"
        isOpen={firstDraftModal}
        toggle={() => setFirstDraftModal(!firstDraftModal)}
        backdrop="static"
      >
        <ModalHeader>
          Upload First Draft
          <span
            className="cross"
            title="Close"
            onClick={() => {
              setFirstDraftModal(false);
            }}
          >
            x
          </span>
        </ModalHeader>
        <ModalBody>
          <div id="uploadDraftFile">
            <div class="d-flex file-group">
              <input
                type="file"
                class="form-control file-control"
                name="document_file"
                required
                onChange={(e, v) => {
                  setSetFirstDraftFile(e.target.files[0]);
                }}
              />
            </div>
            <div class="row my-4">
              <div class="col-sm-12 col-md-12">
                <div class="d-flex">
                  <i
                    class="mr-2 fa fa-info-circle fa-2x"
                    aria-hidden="true"
                  ></i>
                  <p class="info">
                    Please upload the First Draft of the Document for the User
                    to verify.
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <button
                  class="custom-button pull-right cta-submit"
                  onClick={(e, v) => {
                    handleFirstDraft(e, v);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/*  First Draft Modal End */}

      {/* First Draft Feedback Modal Start */}
      <Modal
        size="lg"
        isOpen={firstDraftFeedbackModal}
        toggle={() => setFirstDraftFeedbackModal(!firstDraftFeedbackModal)}
        backdrop="static"
      >
        <ModalHeader>
          Draft Feedback
          <span
            className="cross"
            title="Close"
            onClick={() => {
              setFirstDraftFeedbackModal(false);
            }}
          >
            x
          </span>
        </ModalHeader>
        <ModalBody>
          <div id="draftFeedback">
            <div class="d-flex">
              <i class="mr-2 fa fa-info-circle fa-2x" aria-hidden="true"></i>
              <p class="info">
                Below is the Feedback from the User based on the{" "}
                <a
                  href={selectedPaper && selectedPaper.first_draft_url_admin}
                  target="_blank"
                  class="sentDoc"
                >
                  Draft provided
                </a>{" "}
                .
              </p>
            </div>
            <div class="d-flex file-group">
              <input
                type="file"
                class="form-control file-control"
                name="document_file"
                required
                onChange={(e, v) => {
                  setSetFirstDraftFile(e.target.files[0]);
                }}
              />
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-5 my-3">
                <div class="text-center">
                  <a
                    href={selectedPaper && selectedPaper.first_draft_reply_url}
                    target="_blank"
                    class="draft-download"
                  >
                    <img
                      class="img-fluid sample-image"
                      src={pdf}
                      alt="Click to download"
                    />
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-7 my-3">
                <div class="row">
                  <div class="col-md-12">
                    <h6>Feedback :</h6>
                    <p>
                      {selectedPaper && selectedPaper.additional_information}
                    </p>
                    <h6>Reply :</h6>
                    <textarea
                      class="fullWidthTextArea"
                      rows="3"
                      onChange={(e, v) => {
                        setAdditionalInformation(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <button
                          class="custom-button my-2 mr-3 cta-submit cta-changes"
                          onClick={(e, v) => {
                            handleFirstDraft(e, v);
                          }}
                        >
                          Make Changes
                        </button>
                      </div>
                      <div class="col-md-6">
                        <button
                          class="custom-button my-2 cta-submit cta-finalize"
                          onClick={(e, v) => {
                            setProgress(selectedPaper.progress);
                            setFirstDraftFeedbackModal(false);
                            setFinalDocumentModal(true);
                          }}
                        >
                          Finalize Draft
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/*  First Draft Feedback Modal End */}

      {/* Final Document Modal Start */}
      <Modal
        size="lg"
        isOpen={finalDocumentModal}
        toggle={() => setFinalDocumentModal(!finalDocumentModal)}
        backdrop="static"
      >
        <ModalHeader>
          Final Document Upload
          <span
            className="cross"
            title="Close"
            onClick={() => {
              setFinalDocumentModal(false);
            }}
          >
            x
          </span>
        </ModalHeader>
        <ModalBody>
          <div id="finalDocUpload">
            <div class="d-flex file-group">
              <input
                type="file"
                class="form-control file-control"
                name="document_file"
                onChange={(e) => {
                  setFinalDocumentFile(e.target.files[0]);
                }}
              />
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-4">
                <div class="form-group mt-4">
                  <label for="uploadedPages">Progress</label>
                  <input
                    type="number"
                    defaultValue={selectedPaper && selectedPaper.progress}
                    class="form-control"
                    min={0}
                    max={100}
                    maxLength={3}
                    name="progress"
                    onChange={(e) => {
                      setProgress(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="form-group mt-4">
                  <label for="uploadedPages">Tentative Pages</label>
                  <input
                    type="number"
                    value={selectedPaper && selectedPaper.tentative_pages}
                    class="form-control"
                    name="tentativePages"
                    disabled=""
                    readonly=""
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="form-group mt-4">
                  <label for="uploadedPages">Final Pages</label>
                  <input
                    type="number"
                    class="form-control"
                    value={finalPages}
                    name="final_pages"
                    onChange={(e) => {
                      setFinalPages(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div class="row my-4">
                <div class="col-sm-12 col-md-9">
                  <div class="d-flex">
                    <i
                      class="mr-2 fa fa-info-circle fa-2x"
                      aria-hidden="true"
                    ></i>
                    <p class="info">
                      By clicking Send, the uploaded documents will be delivered
                      to the respective customer’s registered email.
                    </p>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <button
                    onClick={(e, v) => {
                      handleFinalDocument(e, v);
                    }}
                    class="custom-button pull-right cta-submit"
                    data-id="D-15"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/*  Final Document Modal End */}

      {/* Final File Modal Start */}
      <Modal
        size="md"
        isOpen={finalFileModal}
        toggle={() => setFinalFileModal(!finalFileModal)}
        backdrop="static"
      >
        <ModalHeader>
          Final File Upload
          <span
            className="cross"
            title="Close"
            onClick={() => {
              setFinalFileModal(false);
            }}
          >
            x
          </span>
        </ModalHeader>
        <ModalBody>
          <div id="uploadDraftFile">
            <div class="d-flex file-group">
              <input
                type="file"
                class="form-control file-control"
                name="document_file"
                required
                onChange={(e, v) => {
                  setFinalFile(e.target.files[0]);
                }}
              />
            </div>
            <div class="row my-4">
              <div class="col-sm-12 col-md-12">
                <div class="d-flex">
                  <i
                    class="mr-2 fa fa-info-circle fa-2x"
                    aria-hidden="true"
                  ></i>
                  <p class="info">Please upload the Final File for the User.</p>
                </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <button
                  class="custom-button pull-right cta-submit"
                  onClick={(e, v) => {
                    handleFinalFile(e, v);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/*  Final File Modal End */}
    </>
  );
}

export default Papers;
