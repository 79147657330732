import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/landing.css";
import "../assets/css/login.css";
import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { postSubmitFormNoAuth } from "../helpers/forms_helper";
import ShowNotification from "../helpers/notification";
import { UserContext } from "./LoginContext";

function Login() {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [pwd, setPwd] = useState();

  const handleLogin = async () => {
    let url, response;
    if (email === "admin") {
      url = process.env.REACT_APP_BASEURL + "adminusers/login";
      response = await postSubmitFormNoAuth(url, { username: email, pwd });
    } else {
      url = process.env.REACT_APP_BASEURL + "students/login";
      response = await postSubmitFormNoAuth(url, { email, pwd });
    }

    if (response.status === 1) {
      localStorage.setItem("user", JSON.stringify(response.data));
      setUser(response.data);
      if (email === "admin") navigate("/admin-dashboard");
      else navigate("/student-dashboard");
    } else {
      ShowNotification(response.message, "Error");
    }
  };

  const handleForgotPassword = async () => {
    console.log(email);
    if(!email){
      ShowNotification("Please enter your email", "Info");
    } else{
      let url = "https://api-texscript.getdemos.in/students/forgotpassword";

      const response = await postSubmitFormNoAuth(url, {
        email: email,
      });
      if (response && response.status === 1) {
        ShowNotification("Reset password email is sent successfully.", "success");
    
      } else {
        ShowNotification(response.message, "Error");
      }
    }
   
  };


  return (
    <>
      <section className="mt-4 mt-44">
        <div class="dashboard-container py-3" id="login-bg"><div class="container-fluid mt-0"><h3 class="admin-1">
          <i class="fa fa-user-circle-o" aria-hidden="true"></i> Login</h3></div></div>
        <div className="container-fluid">
          <div id="login-bg" className="py-5">
            <div className="row mr-0">
              <div className="col-sm-12 col-md-4 offset-md-4">
                <div className="card card-light">
                  <div className="card-body">
                    <form>
                      <div className="form-group">
                        <label for="email">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                          &nbsp;E-mail
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          onChange={(e, v) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label for="password">
                          {" "}
                          <i class="fa fa-unlock-alt" aria-hidden="true"></i>{" "}
                          &nbsp;Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          onChange={(e, v) => setPwd(e.target.value)}
                                       />
                       <label
                                            className="forgot-pass" 
                                        style={{
                                          cursor: "pointer",
                                          color: "red",
                                        }}
                                    onClick={handleForgotPassword}
                                      >
                                        Forgot Password?
                                      </label>
                      </div>
                      {/* <div className="custom-control form-control-lg custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="rememberMe"
                    />
                    <label className="custom-control-label" for="rememberMe">
                      Remember me
                    </label>
                  </div> */}
                      <a
                        className="cta cta-fill cta-curvy cta-login"
                        href="javascript:void(0)"
                        onClick={handleLogin}
                      >
                        Login &nbsp;<i class="fa fa-paper-plane-o" aria-hidden="true"></i>

                      </a>
                      <p className=" text-center bottom-text abc">
                        New User?{" "}
                        <span>
                          <a href="register">Register Here</a>
                        </span>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
