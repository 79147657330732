import React, { useEffect, useState } from "react";


import { Row, Col, Label, Button } from "reactstrap";
import ShowNotification from "../helpers/notification";
import { postSubmitForm } from "../helpers/forms_helper";

function ResetPassword() {

  const [id, setId] = useState();
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const [isPasswordUpdatedConfirm, setIsPasswordUpdatedConfirm] = useState(false);
  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("id")) {
      setId(urlParams.get("id"));
    }
  }, []);
  const handleResetPassword = async (e, v) => {
    if (!id) {
      ShowNotification("Invalid request.", "Error");
   
    } else if (isPasswordUpdated !== isPasswordUpdatedConfirm) {
      ShowNotification("Confirm password did not match.", "Error");
   
    } else {
      let url = "https://api-texscript.getdemos.in/students/via_email_resetpassword";
   
      const response = await postSubmitForm(url, {
        id: id,
        pwd: isPasswordUpdated,
      });
      if (response && response.status === 1) {
        ShowNotification("Password updated successfully.", "success");
   
        setIsPasswordUpdated(true);
      } else {
        ShowNotification(response.message, "Error");
     
      }
    }
  };

  return (
    <section className="mt-4 mt-44">
    <div class="dashboard-container py-3" id="login-bg"><div class="container-fluid mt-0"><h3 class="admin-1">
      <i class="fa fa-user-circle-o" aria-hidden="true"></i>Reset Password</h3></div></div>
    <div className="container-fluid">
      <div id="login-bg" className="py-5">
        <div className="row mr-0">
          <div className="col-sm-12 col-md-4 offset-md-4">
            <div className="card card-light">
              <div className="card-body">
                <form>
                  <div className="form-group">
                    <label for="email">
                      <i class="fa fa-unlock-alt"aria-hidden="true"></i>
                      &nbsp;New Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      onChange={(e, v) => setIsPasswordUpdated(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="password">
                      {" "}
                      <i class="fa fa-unlock-alt" aria-hidden="true"></i>{" "}
                      &nbsp;Confirm Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      onChange={(e, v) => setIsPasswordUpdatedConfirm(e.target.value)}
                                   />
                   {/* <label
                                        className="forgot-pass" 
                                    style={{
                                      cursor: "pointer",
                                      color: "red",
                                    }}
                                onClick={handleForgotPassword}
                                  >
                                    Forgot Password?
                                  </label> */}
                  </div>
                  {/* <div className="custom-control form-control-lg custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="rememberMe"
                />
                <label className="custom-control-label" for="rememberMe">
                  Remember me
                </label>
              </div> */}
                  <a
                    className="cta cta-fill cta-curvy cta-login"
                    href="javascript:void(0)"
                    onClick={handleResetPassword}
                  >
                    Reset  &nbsp;<i class="fa fa-paper-plane-o" aria-hidden="true"></i>

                  </a>
                  {/* <p className=" text-center bottom-text abc">
                    New User?{" "}
                    <span>
                      <a href="register">Register Here</a>
                    </span>
                  </p> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default ResetPassword;
