import React from 'react'

function TermsAndConditions() {
    return (


        <>
            <section>
                <div class="container-fluid">
                    <div className="row">
                        <div className="dashboard-container mt-4 mt-44" id="login-bg">
                            <img src='./tc.png' className='responsive img-re' />
                        </div>
                    </div>
                    <div className="card" id="about_us">

                        <a name="about_us"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Introduction</h3>

                            <p>
                                Your access to and use of Website are conditioned on your full acceptance and compliance with these Terms and Conditions and this Website Privacy Policy, which are published at Website and which are incorporated herein by reference (“Privacy Policy”). These Terms and Conditions and Privacy Policy are applied to all visitors, users and others who access or use this Website.</p>
                            <p>
                                By accessing or using this Website, you agree to be bound by these Terms and Conditions and Privacy Policy. If you disagree with these Terms and Conditions and/or Privacy Policy or any part of them, you must not use this Website. </p>
                            <p>
                                Capitalized terms defined in these Terms and Conditions shall have no other meaning but set forward in this section. The following terminology is applied to these Terms and Conditions, Privacy Policy and Refund and Revision Policy: “Client”, “You” and “Your” refers to you, the person accessing this Website and accepting these Terms and Conditions. “We”, “Us” and “Ourselves” refers to website. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.

                            </p>
                        </div>
                    </div>

                    <div className="card" id="our_services">
                        <a name="our_services"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Eligibility</h3>
                            <p>By using our Services, you represent and warrant that (a) all registration information you submit to Website is truthful and accurate; (b) you will maintain the accuracy of such information; (c) you are 18 years of age or older and/or have full legal capacity to enter into legally binding relations; and (d) your use of the Services does not violate any applicable law, regulation, and/or your university/institute/ college/ school rules. By accepting these Terms and Conditions you understand that this is your own responsibility to prepare and submit your own work to your educational institution (college/university/school). You take full responsibility of any plagiarism of the document which you get typed from us  </p>
                            <p>Your profile may be deleted and Services provided to you may be terminated without warning, if we believe that you are less than 18 years of age and/or do not have full legal capacity to enter into legally binding relations.</p>

                        </div>
                    </div>

                    <div className="card" id="why_us">
                        <a name="why_us"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Provided Services</h3>
                            <p>Subjected to full compliance with these Terms and Conditions, Website shall provide academic retyping services as described more fully on the Website (“Our Services”).</p>
                            <p>Services may include, but not be limited to, providing our Clients with dissertations, research papers, book reports, term papers, and other types of assignments retyped in requested format by Our team (“Paper”) which are intended for research/reference purposes and for your personal use only. All Papers are provided to Clients to assist to typeset their papers in required format.  </p>
                            <p>Please note that Services may be provided only to the users who register on our Website by submitting appropriate and accurate information in the form given at the Website at the time of registration and the Website may charge fees for such Services. The Services are provided according to the provisions of these Terms and Conditions and the specific commercial provisions and policies (including Privacy Policy, Refund Policy, etc.) as detailed on the Website, and these provisions and policies may be amended or changed from time to time.</p>
                            <h3 className='heading-1'> The format of the Papers we Provide:</h3>
                            <ol>
                                <li>Font Size & Style: 12 point & Times New Roman as per MS Word file.</li>
                                <li>Bibliography/ References on a separate Page.</li>
                                <li>1.5 line spacing as per MS Word file.</li>
                                <li>Approximately 400 words per page.</li>
                                <li>2.54 cm margin top, bottom, left, right.</li>

                            </ol>
                            <p></p>
                            <h3 className='heading-1'> Title page containing less than 40 words is free of charge.</h3>
                            <p>The charges shall be calculated according to the format of Paper provided in the “Sample document” on Website, the type of service and the number of final pages. </p>

                        </div>
                    </div>





                    <div className="card" id="our_services">
                        <a name="our_services"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Placing & Processing an Order</h3>
                            <p>While placing an order, client must provide accurate, truthful and complete information. Clients are solely responsible for any possible consequences and misunderstandings, in case clients provide us with inaccurate and/or incorrect and/or unfaithful information.</p>
                            <p>Please be advised that you will be asked to give final confirmation to the tentative number of pages we provide on your dashboard against “Tentative Pages”. We must receive your confirmation within 3 hours after placing your order (and within 30 minutes for urgent orders). After the confirmation of tentative number of pages, the client has to pay a token amount (non-refundable) within 10 minutes to start the service.  Orders without confirmation of tentative number of pages and partial payment (token amount) will not be worked on and may be delayed and you accept sole responsibility for such delay. Website guarantees that the delivered Paper will meet only confirmed requirements. You must not change the instructions once you have confirmed them. The “First draft” of the paper will be provided to the client with a chance for proof reading and checking the format of the paper according the instruction he provided at the time of order. That client has to revert to “First draft” confirmation within 3 hours after receiving “First draft” (and within 30 minutes for urgent orders) with instruction. After incorporating the instructions provided by the client on “First draft”, the Final paper will be delivered to the client on receipt of payment of the remaining amount.  Any alterations after delivering the Final paper shall only be entertained through email communication at support@texscript.com and may be considered as additional order, thereby requiring additional payment.</p>

                        </div>
                    </div>




                    <div className="card" id="our_services">
                        <a name="our_services"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Payment</h3>
                            <p>All payments are due upon receipt. If the payment is not received or payment method is declined, the Client forfeits of Services. </p>
                            <p>All fees are exclusive of all taxes and/or levies, and/or duties imposed by taxing authorities, and you shall be responsible for payment of all such taxes and/or levies, and/or duties. You agree to pay any such taxes that might be applicable to your use of the Services and payments made by you under these Terms.</p>

                            <p>If at any time you contact your bank or credit card company and decline or otherwise reject the charge of any payment, this act will be considered as a breach of your obligation and commitment hereunder and your use of the Services will be automatically terminated.</p>

                            <p>Use of stolen or illegally occupied credit/ debit card and/or any payment method like Net-banking, UPI payments etc is considered as a serious offense.</p>

                            <p>Website closely cooperates with our payment provider to prevent and fight online fraud. In case of any online fraud, appropriate concerned authorities will be contacted immediately.</p>

                            <p>Website intently helps out our payment service provider to prevent and fight against online fraud. In case of occurrence of any online fraud, appropriate state authorities will be reached right away.</p>

                            <p>The Website reserves the right to change its prices at any time in its sole discretion and such changes or modifications shall be posted online at the Website under the “Get a Quote” tab and become effective immediately without need for further notice to any Client and/or user.</p>


                            <h3 className='heading-1'>By providing payment method information and authorizing payments, You warrant that:</h3>
                            <ol>
                                <li>You are legally authorized to provide such information.</li>
                                <li>You are legally authorized to make payments using the payment method.</li>


                            </ol>
                            <p>As far as it's allowed by applicable law and according to our Privacy Policy, you acknowledge and agree that we may use third-party service providers to process payments (payment facilitators). Hence it is considered that You will also abide yourself with terms and conditions of our the Payment Service Provider.</p>
                        </div>
                    </div>


                    <div className="card" id="our_services">
                        <a name="our_services"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Discount Policy</h3>
                            <p>Since we are committed to provide the most economic document typesetting services to our clients, for this reason we always look for ways to offer best value to our clients. We use two discount system methods to provide discounts to our clients. One method is to provide a flat discount to all users existing, the other method provides user specific discount to some specific users. The Website reserves all rights to change, modify or remove any discount offer at any time without any prior notice or consent. </p>
                        </div>
                    </div>





                    <div className="card" id="our_services">
                        <a name="our_services"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Revisions</h3>
                            <p>Any revision request or complaint in regards to a Paper that the Website has provided must be made through email within the revision period (“Revision Period”). The Website offers a 14-day Revision Period for Papers less than 20 pages/slides and a 30-day period for Papers more than 20 pages. Revision Period begins on the date of Client's order deadline and expires on the last day of the Revision Period. After that point, no revision and/or complaint will be accepted. Revision period can be extended to 60 days in exceptional cases.</p>


                            <p>We recognize that orders vary in size and complexity; as a result, dissertation, thesis and/or other sufficiently large assignment may be granted a 30-day Revision Period.</p>

                            <p>  In case a request for revision is not submitted within the Revision Period, We tacitly accept that the Client is satisfied with the Paper and requires no further actions to be taken in regards to the Paper unless extra payment is provided or a new order is placed.</p>

                            <p>Upon receiving your completed assignment you are entitled to a free revision if the Paper fails to meet your instructions or defined the requirements in any way. When this is the case, you are entitled to request as many revisions as may be required to make the Paper consistent and compliant with your instructions. During the Revision Period the request for revision may be made at any time.</p>

                            <p>All revisions must be based on the original order instructions. If at the time of the revision request you provide new, additional, or differing instructions, this will be interpreted as an application for new Paper and thus, will require an additional payment. Furthermore, if you request a revision after the Revision Period, it will also be considered as a new order requiring an additional payment.</p>


                        </div>
                    </div>





                    <div className="card" id="our_services">
                        <a name="our_services"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Verification</h3>
                            <p>We may require you to supply us with personal identifying information, and we may also legally consult other sources to obtain information about you. By accepting these Terms and Conditions, you authorize us to make any inquiries we consider necessary to validate the information that you provide us with. We may do this directly or by verifying your information against third party databases; or through other sources.</p>


                            <p>Essentially, verification procedure involves, inter alia, confirming that the order is authentic and that the cardholder is aware of charges by placing a phone call to them, and in certain cases by requesting some additional documents to be submitted for verification to our Risk Department. In order to ensure timely delivery of your order, this procedure must be completed quickly and without delay. Therefore, it is vital to provide accurate and valid phone numbers. Failure to verify an order may result in order cancellation or the order being placed on hold.</p>

                            <p>You consent to our processing your personal information for the purposes of providing the Services, including for verification purposes as set out herein. You also consent to the use of such data for communicating with you, for statutory and accounting purposes. You acknowledge that you have read and consented to the Website’s Privacy Policy.</p>



                        </div>
                    </div>



                    <div className="card" id="our_services">
                        <a name="our_services"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp; Limitation of Liability</h3>
                            <p>We will not be liable to you in relation to the contents of, the use of, or otherwise in connection with, this Website:</p>
                            <p>for any plagiarism detected in your paper (since we are only typesetters)</p>
                            <p>for the outcome or consequences of submission the Paper to any academic institution; and
                                excludes all liability for damages arising out of or in connection with your use of this Website. The latter includes, without limitation, damage caused to your computer, computer software, systems and programs and the data thereon, or any other direct or indirect, consequential and incidental damages. We do not condone, encourage or knowingly take part in any acts against academic honesty and integrity. You are solely responsible for any disciplinary actions arising from improper, unethical, and/or illegal use of the Paper, including the cases of plagiarism, lawsuits, poor grading, expulsion, academic probation, loss of scholarships, awards, grants, prizes, titles, positions, failure, suspension, or any other disciplinary or legal actions.
                            </p>




                        </div>
                    </div>




                    <div className="card" id="our_services">
                        <a name="our_services"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Disclaimer</h3>
                            <p>The materials on the Website are provided ‘as is.’ The Website makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, The Website does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Website or otherwise relating to such materials or on any sites linked to this site. We make no representations that the Website is appropriate or available for all jurisdictions. Those who access or use the Website from other jurisdictions are entirely responsible for compliance with all applicable foreign; laws and regulations.</p>



                        </div>
                    </div>


                    <div className="card" id="our_services">
                        <a name="our_services"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Copyright</h3>
                            <p>The Paper provided to you is just a retyping of the paper provided by you, so Website cannot claim any copyright and other intellectual property rights and hence all the rights of the paper remain yours subject to the clearance of service charges.</p>



                        </div>
                    </div>



                    <div className="card" id="our_services">
                        <a name="our_services"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Testimonial</h3>
                            <p>We post Clients` testimonials on our Website which may contain personal information (first name or initials). Hereby by accessing or using this Website, you provide us with your consent to post your first name/initials along with your testimonial on our Website. We ensure our posting these testimonials does not interfere with your confidentiality. If you wish to request the removal of your testimonial, you may contact us at <a href='#'> support@texscript.com</a> </p>

                            <p>You acknowledge that if You send us any testimonials, comments, suggestions, ideas, notes, concepts or other information, (collectively, the "Information"), You grant us the irrevocable, perpetual right to use, alter, publish or delete the Information for any purpose whatsoever without any compensation. You must not do any of the following:</p>
                            <ol>
                                <li>Submit false, inaccurate, or misleading information.</li>
                                <li>Edit or otherwise modify any material on the Website.</li>
                                <li>Send material that infringes on any other intellectual property rights of others or on the privacy rights of others.</li>
                                <li>Send material that is obscene, defamatory, threatening, harassing, abusive, hateful, or embarrassing to another user or any other person or entity.</li>
                                <li>Promote sexually explicit or pornographic material.</li>
                                <li>Send material that reveals trade secrets, unless You own them or have the permission of the owner.</li>
                                <li>Intentionally or unintentionally violate or encourage conduct that would violate any local, state, or federal law.</li>
                                <li>Attempt to breach the security of the Website.</li>
                                <li>Send advertisements, promotional materials, chain letters or pyramid schemes, spam, mass mailing or other solicitations of business; or impersonate another person.</li>

                            </ol>


                        </div>
                    </div>
                    <div className="card" id="our_services">
                        <a name="our_services"></a>
                        <div className="card-body">
                            <h3 className='heading'><i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;Notification of Changes</h3>
                            <p>We reserves the right to change these Terms and Conditions at any time and your continued use of the Website will signify your acceptance of any adjustment, improvements and/or alterations to these Terms and Conditions. You are, therefore, advised to re-read these Terms and Conditions on a regular basis..</p>

                        </div>
                    </div>
                    <p>&nbsp;</p>
                </div>

            </section>

        </>






















    )
}

export default TermsAndConditions