import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/dashboard.css";
import { useEffect, useState } from "react";
import {
  postSubmitForm,
  postSubmitForm_withformdata,
  postSubmitFormNoAuth,
} from "../helpers/forms_helper";
import { Modal, ModalHeader, ModalBody, Row, Col, Label } from "reactstrap";
import ShowNotification from "../helpers/notification";
import moment from "moment";
import yes from "../assets/icons/check-solid.svg";
import no from "../assets/icons/times-solid.svg";
import pdf from "../assets/icons/pdf-icon.png";
import del from "../assets/icons/trash-can-regular.svg";

function Coupons() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [coupons, setCoupons] = useState([]);

  const [students, setStudents] = useState([]);

  const [newCoupon, setNewCoupon] = useState({ student_id: "", discount: "" });

  const [filteredCoupons, setFilteredCoupons] = useState([]);

  const [filterFields, setFilterFields] = useState({
    code: "",
    name: "",
    email: "",
  });

  useEffect(() => {
    loadStudents();
    loadCoupons();
  }, []);

  const applyFilter = () => {
    let funnel = JSON.parse(JSON.stringify(coupons));

    // filter for code
    if (filterFields.code !== "") {
      funnel = funnel.filter((coupon) =>
        coupon.code.toLowerCase().includes(filterFields.code.toLowerCase())
      );
    }

    // filter for name
    if (filterFields.name !== "") {
      funnel = funnel.filter((coupon) =>
        (coupon.student.first_name + " " + coupon.student.last_name)
          .toLowerCase()
          .includes(filterFields.name.toLowerCase())
      );
    }

    // filter for email
    if (filterFields.email !== "") {
      funnel = funnel.filter((coupon) =>
        coupon.student.email
          .toLowerCase()
          .includes(filterFields.email.toLowerCase())
      );
    }

    setFilteredCoupons(funnel);
  };

  useEffect(() => {
    applyFilter();
  }, [filterFields]);

  const loadCoupons = async () => {
    let url = process.env.REACT_APP_BASEURL + "coupons/getall";
    let response = await postSubmitForm(url, user.token, {});

    if (response.status === 1) {
      setCoupons(response.data);
      setFilteredCoupons(response.data);
    } else {
      ShowNotification(response.message, "Error");
    }
  };

  const loadStudents = async () => {
    let url = process.env.REACT_APP_BASEURL + "students/getall";
    let response = await postSubmitForm(url, user.token, {});

    if (response.status === 1) {
      setStudents(response.data);
    } else {
      ShowNotification(response.message, "Error");
    }
  };

  const [generateCouponModal, setGenerateCouponModal] = useState(false);

  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const handleGenerateCoupon = async (e, v) => {
    e.preventDefault();

    console.log(newCoupon);

    if (
      newCoupon &&
      (newCoupon.student_id === "" ||
        newCoupon.discount === "" ||
        newCoupon.discount === "0" ||
        Number(newCoupon.discount) > 100)
    ) {
      return ShowNotification("Invalid inputs.", "error");
    }

    let url = process.env.REACT_APP_BASEURL + "coupons/generate";
    let response = await postSubmitForm(url, user.token, newCoupon);
    if (response.status === 1) {
      loadCoupons();
      setGenerateCouponModal(false);
      setNewCoupon({ student_id: "", discount: "" });
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };

  const handleDeleteCoupon = async (e, v) => {
    e.preventDefault();

    let url = process.env.REACT_APP_BASEURL + "coupons/delete";
    let response = await postSubmitForm(url, user.token, {
      code: selectedCoupon.code,
    });
    if (response.status === 1) {
      loadCoupons();
      ShowNotification(response.message, "success");
    } else {
      ShowNotification(response.message, "error");
    }
  };
  return (
    <>
      <section className="mt-4 mt-44">
        <div className="container-fluid">
          <div className="dashboard-container py-3" id="login-bg">
            <div className="row">
              <div className="col-md-6">
                <div class="container-fluid">
                  <h3 class="admin-1">
                    {" "}
                    <i class="fa fa-gift" aria-hidden="true"></i>
                    &nbsp;Coupons
                  </h3>
                </div>
              </div>

              <div className="col-md-6 mt-15">
                <button
                  className="button bg right"
                  onClick={() => {
                    setGenerateCouponModal(!generateCouponModal);
                  }}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i> Generate Coupon
                </button>
              </div>
            </div>
          </div>

          <div className="card mb-2">

            <div className="table-responsive  admin-dashboard">
              <table class="table table-striped">
                <thead className="table-bg">
                  <tr>
                    <th>#</th>
                    <th>
                      Coupon Code
                      <br />
                      <input
                        type="text"
                        onChange={(e) => {
                          setFilterFields({
                            ...filterFields,
                            code: e.target.value,
                          });
                        }}
                      />
                    </th>
                    <th>Discount Percent</th>
                    <th>
                      Name
                      <br />
                      <input
                        type="text"
                        onChange={(e) => {
                          setFilterFields({
                            ...filterFields,
                            name: e.target.value,
                          });
                        }}
                      />
                    </th>
                    <th>
                      Email
                      <br />
                      <input
                        type="text"
                        onChange={(e) => {
                          setFilterFields({
                            ...filterFields,
                            email: e.target.value,
                          });
                        }}
                      />
                    </th>
                    <th>Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCoupons &&
                    filteredCoupons.map((item, index) => {
                      return (
                        <tr>
                          {/* serial number */}
                          <td>{index + 1}</td>
                          {/* code */}
                          <td>{item.code}</td>
                          {/* discount */}
                          <td>{item.discount + "%"}</td>
                          {/* user name */}
                          <td style={{ textAlign: "left" }}>
                            {item.student.first_name +
                              " " +
                              item.student.last_name}
                          </td>
                          {/* email */}
                          <td style={{ textAlign: "left" }}>
                            {item.student.email}
                          </td>
                          {/* is_used */}
                          <td>
                            {item.is_used ? (
                              <span
                                style={{ color: "green", fontWeight: "bold" }}
                              >
                                Used
                              </span>
                            ) : (
                              <span
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                Unused
                              </span>
                            )}
                          </td>
                          {/* action */}
                          <td>
                            {item.is_used ? (
                              ""
                            ) : (
                              <img
                                onClick={(e) => {
                                  setSelectedCoupon(item);
                                  handleDeleteCoupon(e);
                                }}
                                src={del}
                                style={{
                                  width: "15px",
                                  cursor: "pointer",
                                }}
                                title="Delete"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between custom-inner-container">
          <p className="text count">Total Count: {filteredCoupons.length}</p>
          <div className="custom-pagination d-flex align-items-center">
            <i className="fa fa-angle-left" aria-hidden="true">
              {"<"}
            </i>
            <p className="text-small pagination">1-15</p>
            <i className="fa fa-angle-right" aria-hidden="true">
              {">"}
            </i>
          </div>
        </div>

      </section>

      {/* Generate Coupon Modal Start */}
      <Modal
        size="lg"
        isOpen={generateCouponModal}
        toggle={() => setGenerateCouponModal(!generateCouponModal)}
        backdrop="static"
      >
        <ModalHeader>
          Generate Coupon
          <span
            className="cross"
            title="Close"
            onClick={() => {
              setGenerateCouponModal(false);
            }}
          >
            x
          </span>
        </ModalHeader>
        <ModalBody>
          <div id="paymentInfo">
            <div className="col-md-12">
              <Row>
                <Col md={4}>
                  <Label>Student</Label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setNewCoupon({
                        ...newCoupon,
                        student_id: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select</option>
                    {students.map((option) => (
                      <option value={option._id}>
                        {option.first_name +
                          " " +
                          option.last_name +
                          " (" +
                          option.email +
                          ")"}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={4}>
                  <Label>Discount</Label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) => {
                      setNewCoupon({ ...newCoupon, discount: e.target.value });
                    }}
                  />
                </Col>
                <Col md={4}>
                  <Label>&nbsp;</Label>
                  <br />
                  <button
                    className="button bg left"
                    onClick={(e) => {
                      handleGenerateCoupon(e);
                    }}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
              <Row>
                <Col md={12}>&nbsp;</Col>
              </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/*  Generate Coupon Modal End */}
    </>
  );
}

export default Coupons;
