import { useEffect, useState, useContext } from "react";
import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/landing.css";
import { UserContext } from "./LoginContext";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { postSubmitFormNoAuth, postSubmitForm } from "../helpers/forms_helper";

function LoginStatus(props) {
  //const user = JSON.parse(localStorage.getItem("user"));
  const { user, setUser } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = async () => {
    setUser();
    localStorage.removeItem("user");
    localStorage.clear();

    let url;
    if (user.first_name == "Admin")
      url = process.env.REACT_APP_BASEURL + "adminusers/logoutall";
    else url = process.env.REACT_APP_BASEURL + "students/logoutall";
    let response = await postSubmitForm(url, user.token, {});
  };

  return (
    <>
      {user && user ? (
        <Dropdown
          id="HeaderDropdown"
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
          }}
        >
          <DropdownToggle caret>Welcome, {user.first_name}</DropdownToggle>
          <DropdownMenu>
            {user && user.first_name === "Admin" ? (
              <>
                <DropdownItem href="/admin-dashboard">Dashboard</DropdownItem>
                <DropdownItem href="/final-papers">Final papers</DropdownItem>
                <DropdownItem href="/declined-papers">
                  Declined papers
                </DropdownItem>
                <DropdownItem href="/user-report">User Report</DropdownItem>
                <DropdownItem href="/coupons">Coupons</DropdownItem>
                <DropdownItem href="/settings">Settings</DropdownItem>
                <DropdownItem href="/admin-change-password">
                  Change Password
                </DropdownItem>
              </>
            ) : (
              <>
                <DropdownItem href="/student-dashboard">Dashboard</DropdownItem>
                <DropdownItem href="/student-profile">My Profile</DropdownItem>
                <DropdownItem href="/student-referrals">
                  My Referral Bonus
                </DropdownItem>
                <DropdownItem href="/student-change-password">
                  Change Password
                </DropdownItem>
              </>
            )}

            <DropdownItem href="/login" onClick={handleLogout}>
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <a className="btn-secondary top top-1" href="/login">
          Login &nbsp;<i class="fa fa-user"></i>
        </a>
      )}
    </>
  );
}

export default LoginStatus;
