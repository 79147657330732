import { useEffect, useState } from "react";
import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/landing.css";
import LoginStatus from "./LoginStatus";

function Header() {
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <header className="main-header">
              <div className="row top-header">
                <div className="col-md-12">
                  <div className="row">
                    <div class="col-md-10">
                      <p className="tp">
                        <i class="fa fa-globe" aria-hidden="true"></i>&nbsp; We
                        the texscript.com provide typesetting services
                        (specifically Latex typesetting services) of scientific
                        documents.
                      </p>{" "}
                    </div>

                    {/* <div class="col-md-2"><i class="fa fa-envelope" aria-hidden="true"></i>
                      &nbsp;&nbsp;info@texscript.com</div> */}
                    <div class="col-md-2">
                      <p className="htp">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        &nbsp;&nbsp;info@texscript.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-10">
                  <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg main-nav px-0">
                      <a className="navbar-brand" href="/">
                        <img src="logo.png" />
                      </a>
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#mainMenu"
                        aria-controls="mainMenu"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="icon-bar icon-bar-1"></span>
                        <span className="icon-bar icon-bar-2"></span>
                        <span className="icon-bar icon-bar-3"></span>
                      </button>
                      <div className="collapse navbar-collapse" id="mainMenu">
                        <ul className="navbar-nav ml-auto text-uppercase f1">
                          <li>
                            <a href="/">
                              <i class="fa fa-home" aria-hidden="true"></i>
                              &nbsp; Home
                            </a>
                          </li>
                          <li>
                            <a href="#about"><i class="fa fa-building" aria-hidden="true"></i>
                              &nbsp;About Us</a>
                          </li>
                          <li>
                            <a href="/#Our-Services">
                              <i class="fa fa-pencil" aria-hidden="true"></i>
                              &nbsp;Our Services</a>
                          </li>
                          <li>
                            <a href="/#Why-Us"><i class="fa fa-question-circle" aria-hidden="true"></i>
                              &nbsp;Why Us</a>
                          </li>
                          <li>
                            <a href="/#get_a_quote">&nbsp;<i class="fa fa-quote-left" aria-hidden="true"></i>
                              &nbsp;Get a Quote</a>
                          </li>
                          <li>
                            <a href="/#how_we_work"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                              &nbsp; How We Work</a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="col-md-2">
                  <LoginStatus />
                </div>
              </div>
            </header>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
