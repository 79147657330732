import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/dashboard.css";
import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Input } from "reactstrap";
import ShowNotification from "../helpers/notification";
import {
  postSubmitForm,
  postSubmitForm_withformdata,
  postSubmitFormNoAuth,
} from "../helpers/forms_helper";
import moment from "moment";

function Home() {
  const user = JSON.parse(localStorage.getItem("user"));

  const [settings, setSettings] = useState(null);
  const [payload, setPayload] = useState({
    document_type: "",
    service_type: "",
    page_count: "",
  });
  const [result, setResult] = useState(null);
  const [noDiscount, setNoDiscount] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    let url = process.env.REACT_APP_BASEURL + "settings/get";
    let response = await postSubmitFormNoAuth(url, {});

    if (response.status === 1) {
      setSettings(response.data);
    } else {
      ShowNotification(response.message, "Error");
    }
  };

  const handleCalculator = async (e, v) => {
    e.preventDefault();

    if (payload.document_type === "") {
      return ShowNotification("Please select Document Type.", "error");
    } else if (payload.service_type === "") {
      return ShowNotification("Please select Service Type.", "error");
    } else if (payload.page_count === "" || payload.page_count === "0") {
      return ShowNotification("Please enter number of pages.", "error");
    }

    if (settings.delay_setting.is_active) {
      if (payload.document_type === "Regular") {
        setDeliveryDate(moment().add(7, "days"));
      } else if (payload.document_type === "Urgent") {
        setDeliveryDate(moment().add(3, "days"));
      }
    } else {
      if (payload.document_type === "Regular") {
        setDeliveryDate(moment().add(4, "days"));
      } else if (payload.document_type === "Urgent") {
        setDeliveryDate(moment().add(2, "days"));
      }
    }
    let url = process.env.REACT_APP_BASEURL + "papers/pricecalculator";
    let response = await postSubmitFormNoAuth(url, payload);
    if (response.status === 1) {
      console.log(response.data);

      setResult(response.data);
      setNoDiscount(
        (
          (Number(response.data) * 100) /
          (100 - settings.general_discount)
        ).toFixed(2)
      );
    } else {
      ShowNotification(response.message, "error");
    }
  };

  return (
    <>
      <section className="mt-4 mt-44">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>

          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="d-block w-100"
                src="slider-3.png"
                alt="First slide"
              />
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100"
                src="slider-2.png"
                alt="Second slide"
              />
            </div>
            <div className="carousel-item">
              <img
                className="d-block w-100"
                src="slider-1.png"
                alt="Third slide"
              />
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <div id="about"></div>
            <span className="sr-only">Next</span>
          </a>
        </div>

        <div className="container-fluid ">
          <br />
          <br />

          <div className="row">
            <div className="col-md-7">
              {" "}
              <div className="card-body">
                <h3 className="heading">
                  <i class="fa fa-check-square-o" aria-hidden="true"></i>
                  &nbsp;&nbsp;About TexScript
                </h3>
                <div id="Our-Services">
                  <p>
                    We the texscript.com provide typesetting services
                    (specifically Latex typesetting services) of scientific
                    documents like Research Papers, Articles, Thesis,
                    Dissertation etc.{" "}
                  </p>
                  <p>
                    Since these days every standard and reputed publisher
                    (Journal, book publisher etc.) prefers to publish the
                    documents that are typeset in latex. So, we are here to
                    typeset your scientific document in Latex within a time and
                    cost-efficient manner.{" "}
                  </p>
                  <br />
                  <br />

                  <p>
                    <h3 className="heading">
                      <i class="fa fa-check-square-o" aria-hidden="true"></i>
                      &nbsp;&nbsp;Our Services
                    </h3>
                    <h3 class="heading-1">
                      {" "}
                      We provide the following typesetting services
                    </h3>

                    <div id="Why-Us"></div>
                    <ol>
                      {settings &&
                        settings.prices &&
                        [
                          ...new Set(
                            settings.prices.map((item) => item.service_type)
                          ),
                        ].map((item) => <li>{item}</li>)}
                    </ol>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              {" "}
              <div className="card-body">
                <img src="about-us.png" className="responsive" />
              </div>
            </div>
          </div>
        </div>

        <section className="why-us">
          <div className="container-fluid ">
            <div className="card-body">
              <h3 className="heading">
                <i class="fa fa-check-square-o" aria-hidden="true"></i>
                &nbsp;&nbsp;Why Us?
              </h3>
              <h3 class="heading-1">
                Due to many reasons, you may face challenges in typesetting your
                document in Latex, like
              </h3>
              <ol>
                <li>
                  Lack of time to typeset the document in Latex due to some
                  other official or personal engagements.
                </li>
                <li>
                  {" "}
                  You feel it better to take help of others in typesetting the
                  document because you prefer to do something more creative in
                  the time rather than typesetting a document.
                </li>
                <li>Due to lack of technical knowledge of Latex.</li>
              </ol>
              <p>
                Whatever the reason may be, we are always here to typeset your
                document for you within stipulated time at a most competitive
                cost.
              </p>
              <h3 class="heading-1">
                Without hesitation you may select us for your typesetting
                services because of the following reasons:
              </h3>
              <ol>
                <li>
                  <b>Our Subject Expert Typesetters:</b> We are a large group of
                  typesetters with sufficiently good knowledge of mathematics,
                  mathematical symbols, notations, operators etc. So, you don’t
                  have to worry that your typist is a layman.
                </li>
                <li>
                  <b> Delivery of document within committed time:</b> Our
                  typesetters and proof-readers are devoted to deliver your
                  typeset document in required format within given time limits.
                </li>
                <li>
                  <b> Confidentiality:</b> We are highly committed to maintain
                  the confidentiality of your research paper, article or thesis
                  content. For this purpose, we get your document typed in many
                  pieces by different experts and then compile it for you by the
                  most reliable core team so that our typesetters and
                  proof-readers may also not get the access of your documents.
                </li>
                <li>
                  <b> &nbsp;We don’t keep you document: &nbsp;</b>: Once the
                  project is successfully delivered to you, we destroy all your
                  writings/ documents from our system to ensure confidentiality.
                  However, if you seek any further help, you may contact us via
                  email (
                  <a href="mailto:support@texscript.com">
                    support@texscript.com
                  </a>
                  ) at any time.
                </li>
              </ol>

              <div class="masthead-subheading At Digital Chunav Prachar">
                <span>
                  <div id="how_we_work"></div>
                  <h1 className="h11">
                    Here you’ll find online Typing Services as per your Needs!
                  </h1>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid ">
            <div className="row">
              <div className="col-md-8">
                <h3 className="heading">
                  <i class="fa fa-check-square-o" aria-hidden="true"></i>
                  &nbsp;&nbsp;How We Work
                </h3>
                <div className="card-body">
                  <h3 class="heading-1">
                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                    &nbsp;Placing an Order
                  </h3>

                  <p>
                    A client has to Login (register) on our website
                    www.texscriprt.com and Upload Document by providing
                    following information:
                  </p>
                  <ol>
                    <li>Name of Document</li>
                    <li>Service Type</li>
                    <li>Tentative Number of Pages</li>
                    <li>Any other additional instruction</li>
                  </ol>
                </div>
                <div className="card-body">
                  <h3 class="heading-1">
                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                    &nbsp;Reviewing order details by admin
                  </h3>

                  <p>
                    We review the order and make corrections in the details
                    (like number of pages, service type etc) provided by client,
                    if necessary.
                  </p>
                </div>
                <div className="card-body">
                  <h3 class="heading-1">
                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                    &nbsp;Acceptance of Corrections
                  </h3>
                  <p>
                    A client either accepts or rejects the corrections made by
                    admin.
                  </p>
                  <p>
                    {" "}
                    In case client accepts the corrections, he/she will have to
                    pay a <strong>“Token Amount”</strong> which indicates the
                    admin to start working on project.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card-body">
                  <img src="./work.png" className="responsive" />
                </div>
              </div>

              <div className="container-fluid ">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-body">
                      <h3 class="heading-1">
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>
                        &nbsp;First Draft
                      </h3>
                      <p>
                        Admin will upload the <strong>“First Draft”</strong> for
                        proof-reading and checking for any errors or deviations
                        from instructions provided at time uploading document.
                        The client either asks us for making any correction or
                        finalise the First Draft.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="card-body">
                      <h3 class="heading-1">
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>
                        &nbsp;Final Document
                      </h3>
                      <p>
                        After incorporating the client’s suggestion, admin will
                        upload the Final Document.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="card-body">
                      <h3 class="heading-1">
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>
                        &nbsp;Payment of Remaining Amount
                      </h3>
                      <p>
                        After viewing the <strong>“Final Document”</strong> the
                        Client pays the remaining amount.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="card-body">
                      <h3 class="heading-1">
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>
                        &nbsp;Delivery of Final Project
                      </h3>
                      <p>
                        After verifying the payment of remaining amount, the
                        admin uploads the Final Project for Client to download.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="card-body">
                      <h3 class="heading">
                        <i class="fa fa-check-square-o" aria-hidden="true"></i>
                        &nbsp;Referral Program
                      </h3>
                      <p>
                        We provide a Referral Bonus to every user which can be accessed by clicking on“My Referral Bonus”. We credit amount earned by the user in <b>“My Referral Bonus”</b> A user can use his earned bonus amount while paying to the Website. The detailed policy of our referral program is given below:
                      </p>
                      <ol>
                        <li>When a new user <b>(“Referred User”) </b>register with our Website using Referral Code of any existing user (“Referrer”), then the “Referrer” gets a bonus amount of 10% of the first completed project of new user.</li>
                        <li>The Website reserves all rights to change modify or remove the above given percentage at any time without any prior notice or consent.</li>
                        <li>The Referral Bonus will be credited only after the completion of first project of the “Referred User”. In any case, no bonus will be given to “Referrer” from the subsequent projects of “Referred User”.</li>
                        <li>A User can use a maximum of <b>25% of total available “Referral Bonus”</b> in one project.</li>

                      </ol>
                      <p>This referral program can be changed/ modified/ ended at any time without any prior notice or consent to users. However, the “Referral Bonus” earned before can be used by user.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="why-us">
          <div className="container-fluid ">
            <h3 className="heading">
              <i class="fa fa-check-square-o" aria-hidden="true"></i>
              &nbsp;&nbsp;Delivery Time We Offer
            </h3>

            <div className="container ">
              <table class="table table-bordered table-striped table-responsive   ">
                <tbody>
                  <tr>
                    <td></td>
                    <td>
                      <strong>Number of Pages</strong>
                    </td>
                    <td>
                      <strong>Priority</strong>
                    </td>
                    <td>
                      <strong>Delivery Time*</strong>
                    </td>
                    <td>
                      <strong>Number of Pages</strong>
                    </td>
                    <td>
                      <strong>Delivery Time*</strong>
                    </td>
                  </tr>

                  <tr>
                    <td rowspan="2">
                      <strong>During Normal Days</strong>
                    </td>
                    <td rowspan="4">
                      <span className="green">Up to 30 Pages</span>
                    </td>
                    <td>Regular</td>
                    <td>3-4 Business Days</td>
                    <td rowspan="4">
                      <span className="redd">More than 30 Pages</span>
                    </td>
                    <td rowspan="4">
                      <b>
                        In such cases delivery time shall be decided by mutual
                        agreement via email communication
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Urgent</p>
                    </td>
                    <td>1-2 Business Days</td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      <strong>During Peak Days</strong>
                    </td>
                    <td>Regular</td>
                    <td>4-7 Business Days</td>
                  </tr>

                  <tr>
                    <td>Urgent</td>
                    <td>2-3 Business Days</td>
                  </tr>
                  <tr>
                    <td colspan="6">
                      <strong id="get_a_quote">
                        <span className="red">*</span>&nbsp; Delivery Time is the time between the payment of
                        Token Amount and Supply of First Draft
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section className="get-quate">
          <div className="card-body">
            <Row>
              <Col md={12}>
                <h3 className="heading">
                  <i class="fa fa-check-square-o" aria-hidden="true"></i>
                  &nbsp;&nbsp;Get a Quote
                </h3>
                <br />
              </Col>
              <Col md={3}>
                <label>Document Type</label>
                <Input
                  type="select"
                  className="form-control"
                  id="document_type"
                  name="document_type"
                  onChange={(e) =>
                    setPayload({
                      ...payload,
                      document_type: e.target.value,
                    })
                  }
                >
                  <option value="">--Select--</option>
                  <option value="Regular">Regular</option>
                  <option value="Urgent">Urgent</option>
                </Input>
              </Col>
              <Col md={5}>
                <label>Service Type</label>

                <Input
                  type="select"
                  className="form-control"
                  id="service_type"
                  name="service_type"
                  onChange={(e) =>
                    setPayload({
                      ...payload,
                      service_type: e.target.value,
                    })
                  }
                >
                  <option value="">--Select--</option>
                  {settings &&
                    settings.prices &&
                    [
                      ...new Set(
                        settings.prices.map((item) => item.service_type)
                      ),
                    ].map((item) => <option value={item}>{item}</option>)}
                </Input>
              </Col>
              <Col md={2}>
                <label>Number of Pages</label>

                <Input
                  type="number"
                  className="form-control"
                  id="page_count"
                  name="page_count"
                  onChange={(e) =>
                    setPayload({
                      ...payload,
                      page_count: e.target.value,
                    })
                  }
                />
              </Col>
              <Col md={2} className="static">
                <br />
                <button
                  className="custom-button cta-submit"
                  style={{ marginTop: "6px", fontWeight: "600" }}
                  onClick={(e, v) => {
                    handleCalculator(e, v);
                  }}
                >
                  Submit
                </button>
              </Col>

              <Col md={12}>
                {result && (
                  <div className="textBackdrop fo-p">
                    <p>
                      <span style={{ fontSize: "1.3rem" }}>
                        <b>
                          Estimated service charge is{" "}
                          {noDiscount != result && (
                            <span style={{ textDecoration: "line-through" }}>
                              {"$" + noDiscount}
                            </span>
                          )}{" "}
                          {"$" + result}
                        </b>
                      </span>

                      <br />
                      <span style={{ fontSize: "1.3rem" }}>
                        <b>
                          Delivery expected by{" "}
                          {deliveryDate &&
                            moment(deliveryDate).format("DD, MMMM, dddd")}
                        </b>
                      </span>
                      <br />
                      <span style={{ fontSize: "1rem" }}>
                        * In case of more than 30 pages, expected delivery date
                        may extend and shall be intimated through email.
                      </span>
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </section>
      </section>
    </>
  );
}

export default Home;
